import { useMemo } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";

import { CHAT_FULLSCREEN_WIDTH, TABLET_MARGIN } from "theme/ui";

const TRANSLATE_X = CHAT_FULLSCREEN_WIDTH + TABLET_MARGIN;

export const Message = ({ message }) => {
  const isPlaying = useSelector((state) => state.player.playing);
  const chatMessagesPlaying = useMemo(() => (isPlaying ? "running" : "paused"), [isPlaying]);

  return (
    <MessageWrapper
      className="overflow-hidden flex flex-col gap-2 bg-black rounded-xxl p-4"
      style={{ animationPlayState: chatMessagesPlaying }}
    >
      <span className="text-12 text-white75 px-2">{message.name}</span>
      <p className="text-14 text-white leading-5 px-2">{message.text}</p>
    </MessageWrapper>
  );
};

const animation = keyframes`
  0%{
    opacity: 0;
    visibility: hidden;
    transform: translateX(${TRANSLATE_X}px);
  }

  20%{
    opacity: 1;
    visibility: visible;
    transform: translateX(${TRANSLATE_X}px);
  }

  50%{
    opacity: 1;
    visibility: visible;
    transform: translateX(${TRANSLATE_X}px);
  }

  75%{
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }

  100%{
    opacity: 0;
    visibility: hidden;
    transform: translateX(0px);
  }
`;

const MessageWrapper = styled.div`
  max-width: ${CHAT_FULLSCREEN_WIDTH}px;
  animation: ${animation} 5s forwards;
`;
