import { useMemo } from "react";

import useWindowSize from "hooks/useWindowSize";

import { screens } from "theme";

const useMediaWidthFitted = () => {
  const windowSize = useWindowSize();

  return useMemo(() => {
    return {
      isMediaReponsive: windowSize.width <= screens.md,
      isMediaDesktop: windowSize.width > screens.md,
    };
  }, [windowSize.width]);
};

export default useMediaWidthFitted;
