/**
 *
 * @param {Number} secondsToTime
 */
export const formatTime = (secondsToTime) => {
  secondsToTime = secondsToTime < 0 ? 0 : secondsToTime;

  let hr = Math.floor(secondsToTime / 3600);
  hr = hr > 0 ? `${("0" + hr).slice(-2)}:` : "";

  const min = ("0" + Math.floor((secondsToTime % 3600) / 60)).slice(-2);
  const sec = ("0" + Math.floor(secondsToTime % 60)).slice(-2);

  return `${hr}${min}:${sec}`;
};
