import axios from "axios";

export const DEFAULT_TIMEOUT = 10 * 1000;

/**
 * @type import("axios").AxiosRequestConfig
 */
const defaultConfig = {
  baseURL: `${process.env.REACT_APP_API_URL || ""}/api/`,
};

export const request = axios.create(defaultConfig);
/* request.interceptors.request.use(async (config) => {
  try {
    const token = await auth.currentUser.getIdToken();

    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  } catch (error) {
    captureError(new Error(`getting authenticated user token: ${error.message}`));
    return Promise.reject(error);
  }
}); */

/* export const unauthorizedRequest = axios.create(defaultConfig);

export const createRequest = (config) => axios.create({ ...defaultConfig, ...config });
 */
