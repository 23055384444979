import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Playback } from "components/Playback";

import { eventTimer } from "services/timer";

import { RECORDING_HISTORY_TYPE } from "lib/recording";
import { MEDIA_EVENT_STATUS, PLAYBACK_MEDIA_TYPES } from "lib/player";
import { DRAWING_UNDOREDO_ACTION, SHAPE_GROUP_CONTROLS } from "lib/drawings";

import { drawingsActions } from "store/drawings";
import { navigationActions } from "store/navigation";
import { usersActions } from "store/users";
import { slidesActions } from "store/slides";
import { messagesActions } from "store/mesages";

export const PlaybackListener = () => {
  const dispatch = useDispatch();

  const currentSlideIndex = useSelector((state) => state.slides.slideIndex);
  const currentFileId = useSelector((state) => state.slides.fileId);

  useEffect(() => {
    const subscription = eventTimer.slides$.subscribe((slideInteraction) => {
      if (slideInteraction) {
        const { slideIndex, fileId } = slideInteraction;

        if (slideIndex !== currentSlideIndex) {
          dispatch(slidesActions.slideIndexChanged(slideIndex));
        }

        if (fileId !== currentFileId) {
          dispatch(slidesActions.fileIdChanged(fileId));
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.drawings$.subscribe((drawingsFromSubscription) => {
      if (drawingsFromSubscription === null || currentSlideIndex === null || currentFileId === null)
        return;

      let drawings = [];
      let freePenDrawings = [];

      drawingsFromSubscription.forEach((drawing) => {
        const { type, data } = drawing;

        if (data && typeof data.slideIndex === "number" && data.fileId) {
          const { slideIndex, fileId } = data;
          if (currentFileId !== fileId || currentSlideIndex !== slideIndex) return;
        }

        if (
          type === RECORDING_HISTORY_TYPE.PATH ||
          type === RECORDING_HISTORY_TYPE.TEXT ||
          data.typeShape === SHAPE_GROUP_CONTROLS.SHAPE_LINE
        ) {
          drawings.push(data);
        }

        if (type === RECORDING_HISTORY_TYPE.RESIZING || type === RECORDING_HISTORY_TYPE.MOVING) {
          if (!data || !data.id) return;

          const drawingIndex = drawings.findIndex((drawing) => drawing.id === data.id);

          if (drawingIndex === -1) return;

          const drawingToUpdate = drawings[drawingIndex];

          // Update drawing dimensions and coordinates
          if (type === RECORDING_HISTORY_TYPE.RESIZING) {
            const { width, height, x, y } = data;

            const drawing = {
              ...drawingToUpdate,
              width,
              height,
              x,
              y,
            };

            drawings[drawingIndex] = drawing;
          }
          // Update drawing coordinates
          if (type === RECORDING_HISTORY_TYPE.MOVING) {
            const { x, y } = data;

            const drawing = {
              ...drawingToUpdate,
              x,
              y,
            };

            drawings[drawingIndex] = drawing;
          }
        }

        if (type === RECORDING_HISTORY_TYPE.PEN) {
          freePenDrawings.push(data);
        }

        if (type === RECORDING_HISTORY_TYPE.CLEAR_WHITEBOARD) {
          drawings = [];
          freePenDrawings = [];
        }

        if (type === RECORDING_HISTORY_TYPE.UNDO) {
          if (data.typeUndo === DRAWING_UNDOREDO_ACTION.MOVE_OR_RESIZE) {
            const { width, height, x, y } = data;

            const drawingIndex = drawings.findIndex((drawing) => drawing.id === data.id);

            if (drawingIndex === -1) return;

            const drawingToUpdate = drawings[drawingIndex];

            if (!drawingToUpdate) return;

            const drawing = {
              ...drawingToUpdate,
              width,
              height,
              x,
              y,
            };

            drawings[drawingIndex] = drawing;
          }

          if (
            data.typeUndo === DRAWING_UNDOREDO_ACTION.WRITE ||
            data.typeUndo === DRAWING_UNDOREDO_ACTION.CREATE
          ) {
            const { id } = data;

            if (!id) return;

            drawings = drawings.filter((drawing) => drawing.id !== id);
          }

          if (data.typeUndo === DRAWING_UNDOREDO_ACTION.DRAW) {
            const { id } = data;

            if (!id) return;

            freePenDrawings = freePenDrawings.filter((drawing) => drawing.id !== id);
          }

          if (data.typeUndo === DRAWING_UNDOREDO_ACTION.DELETE) {
            drawings.push(data);
          }
        }

        if (type === RECORDING_HISTORY_TYPE.REDO) {
          if (data.typeRedo === DRAWING_UNDOREDO_ACTION.MOVE_OR_RESIZE) {
            const { width, height, x, y } = data;

            const drawingIndex = drawings.findIndex((drawing) => drawing.id === data.id);

            if (drawingIndex === -1) return;

            const drawingToUpdate = drawings[drawingIndex];

            if (!drawingToUpdate) return;

            const drawing = {
              ...drawingToUpdate,
              width,
              height,
              x,
              y,
            };

            drawings[drawingIndex] = drawing;
          }

          if (
            data.typeRedo === DRAWING_UNDOREDO_ACTION.WRITE ||
            data.typeRedo === DRAWING_UNDOREDO_ACTION.CREATE
          ) {
            drawings.push(data);
          }

          if (data.typeRedo === DRAWING_UNDOREDO_ACTION.DRAW) {
            freePenDrawings.push(data);
          }

          if (data.typeRedo === DRAWING_UNDOREDO_ACTION.DELETE) {
            const { id } = data;

            if (!id) return;

            drawings = drawings.filter((drawing) => drawing.id !== id);
          }
        }
      });

      dispatch(drawingsActions.drawingsUpdated(drawings));
      dispatch(drawingsActions.freePenUpdated(freePenDrawings));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [currentSlideIndex, currentFileId]);

  useEffect(() => {
    const subscription = eventTimer.medias$.subscribe(({ medias }) => {
      if (medias[PLAYBACK_MEDIA_TYPES.CAMERA]) {
        dispatch(navigationActions.isPresentingCameraUpdated(true));
      } else {
        dispatch(navigationActions.isPresentingCameraUpdated(false));
      }

      if (medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]) {
        dispatch(navigationActions.screenSharingActivated());
      } else {
        dispatch(navigationActions.screenSharingDesactivated());
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.presenter$.subscribe((presenter) => {
      dispatch(usersActions.presenterUpdated(presenter));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.videoSharings$.subscribe((videoSharing) => {
      if (videoSharing) dispatch(navigationActions.videoSharingActivated());
      else dispatch(navigationActions.videoSharingDesactivated());
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const subscription = eventTimer.messages$.subscribe((messagesFromSubscription) => {
      dispatch(messagesActions.addNewMessages(messagesFromSubscription));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = eventTimer.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.CAMERA].subscribe(
      (cameraStatus) => {
        if (cameraStatus === MEDIA_EVENT_STATUS.CAN_PLAY) {
          dispatch(navigationActions.isLoadingCameraUpdated(false));
        } else {
          dispatch(navigationActions.isLoadingCameraUpdated(true));
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = eventTimer.playbackMediasStatuses$[
      PLAYBACK_MEDIA_TYPES.SCREEN_SHARING
    ].subscribe((screenShareStatus) => {
      if (screenShareStatus === MEDIA_EVENT_STATUS.CAN_PLAY) {
        dispatch(navigationActions.isLoadingScreenShareUpdated(false));
      } else {
        dispatch(navigationActions.isLoadingScreenShareUpdated(true));
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return <Playback />;
};
