import findLast from "lodash.findlast";

/**
 *
 * @param {import("./data").PlaybackData} data
 * @param {Number} time
 */
export const getPointerAtTime = (data, time) => {
  const pointer = findLast(
    data.pointers,
    ([pointerTime]) => time >= pointerTime
  );
  return pointer ? { x: pointer[1], y: pointer[2] } : { x: -1, y: -1 };
};
