import React, { useCallback, useEffect, useMemo } from "react";
import { nanoid } from "@reduxjs/toolkit";
import lottie from "lottie-web/build/player/lottie_light";

import loaderAnimation from "./tutoom-loader-animation.json";
import cameraAnimation from "./tutoom-loader-speaker-animation.json";
import presentationAnimation from "./tutoom-loader-presentation-animation.json";

const DEFAULT_SIZE = 60;

export const AppLoader = ({ loaderType, size }) => {
  const id = useMemo(() => nanoid(10), []);

  const getAnimation = useCallback(() => {
    switch (loaderType) {
      case "camera":
        return cameraAnimation;
      case "screenShare":
        return presentationAnimation;
      default:
        return loaderAnimation;
    }
  }, [loaderType]);

  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: document.getElementById(id),
      animationData: getAnimation(),
    });

    return () => {
      lottieAnimation.destroy();
    };
  }, [id]);

  return <div id={id} style={{ width: size, height: size }} />;
};

AppLoader.defaultProps = {
  size: DEFAULT_SIZE,
};
