/**
 *
 * @param {PlaybackData} data
 * @param {Number} time
 */
export const getVideoSharingAtTime = (data, time) => {
  const videoSharingAtTime = data.videoSharings.find((s) => time >= s.started && time < s.finished);

  return videoSharingAtTime || null;
};

/**
 *
 * @param {PlaybackData} data
 */
export const getVideos = (videoSharings) => {
  const set = new Set();
  const videos = [];
  videoSharings.forEach((video) => {
    const { videoId, data } = video;
    if (!set.has(videoId)) {
      videos.push(data);
    }

    set.add(video.videoId);
  });

  return videos;
};
