import React from "react";

import { useVideoSharing } from "hooks/useVideoSharing";

export const VideoSharingPresentation = () => {
  const { video } = useVideoSharing();

  if (video === null) return null;

  return (
    <div className="w-full h-full flex justify-center items-center bg-black25 p-12">
      <div className="w-auto flex flex-col items-center bg-white rounded-xxl p-6">
        <span className="select-none text-14 text-center text-black">
          Estamos mostrando un video. Haz click en el siguiente enlace:
        </span>
        <a
          href={video.data.url}
          target="_blank"
          rel="noreferrer"
          className="flex items-center bg-turquoise font-bold outline-none text-14 text-white tracking-135 rounded-xxl mt-12 px-6 py-4 select-none"
        >
          Ir al enlace
        </a>
      </div>
    </div>
  );
};
