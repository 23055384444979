import { createSlice } from "@reduxjs/toolkit";

export const notesSlice = createSlice({
  name: "notes",
  initialState: [],
  reducers: {
    received: (state, action) => action.payload,
  },
});

export const notesActions = notesSlice.actions;
export const notesReducer = notesSlice.reducer;
