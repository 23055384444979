import { createSlice } from "@reduxjs/toolkit";

export const slidesSlice = createSlice({
  name: "slides",
  initialState: {
    slides: [],
    thumbnails: [],
    slideIndex: null,
    fileId: null,
    slidesVisible: false,
  },
  reducers: {
    initialized: (state, action) => {
      const { thumbnails, slides } = action.payload;
      state.thumbnails = thumbnails;
      state.slides = slides;
    },
    slidesVisibleToggled: (state) => {
      state.slidesVisible = !state.slidesVisible;
    },
    slidesVisibleClosed: (state) => {
      state.slidesVisible = false;
    },
    slideIndexChanged: (state, action) => {
      state.slideIndex = action.payload;
    },
    fileIdChanged: (state, action) => {
      state.fileId = action.payload;
    },
  },
});

export const slidesActions = slidesSlice.actions;
export const slidesReducer = slidesSlice.reducer;
