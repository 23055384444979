import { request } from "config/request";

export const getPlaybackData = async (token) => {
  try {
    const { data } = await request.get("v1/data", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};
