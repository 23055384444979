import React from "react";
import getStroke from "perfect-freehand";
import { useSelector } from "react-redux";

import { getSvgPathFromStroke } from "lib/drawings";

export const FreePen = ({ zoomFactor, drawingOffset }) => {
  const freePenData = useSelector((state) => state.drawings.freePen);

  return (
    <g>
      {freePenData.map((drawing) => {
        const { id: drawingId, strokeWidth, color, points } = drawing;
        const _points = typeof points === "string" ? JSON.parse(points) : points;
        const stroke = getStroke(_points, {
          size: (strokeWidth || 3) / zoomFactor,
          thinning: 0.2,
          smoothing: 0.5,
          streamline: 0.5,
        });

        return (
          <path
            key={drawingId}
            id={`fp${drawingId}`}
            className={`stroke-palette-${color} fill-palette-${color}`}
            d={getSvgPathFromStroke(stroke)}
            transform={`translate(${drawingOffset.x * zoomFactor}, ${
              drawingOffset.y * zoomFactor
            }) scale(${zoomFactor})`}
          />
        );
      })}
    </g>
  );
};
