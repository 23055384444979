import { getItem } from "services/localStorage";

export const DEFAULT_LOCALE = "en";

export const SUPPORTED_LOCALES = ["en", "es"];

export const getDefaultLocale = () => {
  const locale = getItem("locale");

  if (locale) return SUPPORTED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;

  const navigatorLang =
    navigator && navigator.language ? navigator.language.substring(0, 2) : DEFAULT_LOCALE;

  return SUPPORTED_LOCALES.includes(navigatorLang) ? navigatorLang : DEFAULT_LOCALE;
};
