import { ICON_TYPES } from "./icons";

export const EXTENSIONS = {
  DOC: ".doc",
  DOCX: ".docx",
  JPEG: ".jpeg",
  JPG: ".jpg",
  PDF: ".pdf",
  PNG: ".png",
  PPT: ".ppt",
  PPTX: ".pptx",
};

export const VIDEO_SHARING_TYPES = {
  YOUTUBE: "YOUTUBE",
  VIMEO: "VIMEO",
};

export const getIconNameByExtension = (extension) => {
  switch (extension) {
    case EXTENSIONS.JPG:
      return ICON_TYPES.JPG_BOX;
    case EXTENSIONS.JPEG:
      return ICON_TYPES.JPEG_BOX;
    case EXTENSIONS.DOC:
      return ICON_TYPES.DOC_BOX;
    case EXTENSIONS.DOCX:
      return ICON_TYPES.DOCX_BOX;
    case EXTENSIONS.PNG:
      return ICON_TYPES.PNG_BOX;
    case EXTENSIONS.PPT:
      return ICON_TYPES.PPT_BOX;
    case EXTENSIONS.PPTX:
      return ICON_TYPES.PPTX_BOX;
    case EXTENSIONS.PDF:
    default:
      return ICON_TYPES.PDF_BOX;
  }
};

export const getIconColorByExtension = (extension) => {
  switch (extension) {
    case EXTENSIONS.DOC:
    case EXTENSIONS.DOCX:
      return "#0077D6";
    case EXTENSIONS.PPT:
    case EXTENSIONS.PPTX:
      return "#FFC754";
    case EXTENSIONS.PDF:
      return "#EB4B60";
    case EXTENSIONS.PNG:
    case EXTENSIONS.JPG:
    case EXTENSIONS.JPEG:
    default:
      return "#23293D";
  }
};

export const getIconColorByVideoType = (type) => {
  switch (type) {
    case VIDEO_SHARING_TYPES.VIMEO:
      return "#0077D6";
    default:
      return "#EB4B60";
  }
};

export const getCurrentSlideImageType = (extension) => {
  switch (extension) {
    case ".jpg":
    case ".jpeg":
      return "image/jpeg";
    case ".png":
    default:
      return "image/png";
  }
};
