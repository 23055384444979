import { createSlice } from "@reduxjs/toolkit";

export const filesSlice = createSlice({
  name: "files",
  initialState: [],
  reducers: {
    received: (state, action) => action.payload,
  },
});

export const filesActions = filesSlice.actions;
export const filesReducer = filesSlice.reducer;
