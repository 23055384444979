import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import { MediaPlayer } from "components/MediaPlayer";
import { AppLoader } from "components/elements/AppLoader";

import { MEDIA_ELEMENT_NAMES } from "lib/player";

import { eventTimer } from "services/timer";

import { BREAKPOINTS } from "theme/ui";

export const ScreenSharingPresentation = () => {
  const screenSharingActive = useSelector((state) => state.navigation.screenSharingActive);
  const isLoadingScreenShare = useSelector((state) => state.navigation.isLoadingScreenShare);
  const isMiniPresentationExpanded = useSelector(
    (state) => state.navigation.isMiniPresentationExpanded
  );

  useEffect(() => {
    /** @type {HTMLVideoElement} */
    const videoEl = document.getElementById(MEDIA_ELEMENT_NAMES.SCREEN_SHARING);
    if (videoEl) {
      eventTimer.initScreenSharingVideo(videoEl);
    }
  }, []);

  return (
    <ScreenSharingWrapper
      className="w-full h-full bg-black25"
      screenSharingActive={screenSharingActive}
      isMiniPresentationExpanded={isMiniPresentationExpanded}
    >
      {screenSharingActive && isLoadingScreenShare ? (
        <div className="w-full h-full flex items-center justify-center bg-dark">
          <AppLoader loaderType="screenShare" />
        </div>
      ) : null}

      <MediaPlayer
        id={MEDIA_ELEMENT_NAMES.SCREEN_SHARING}
        mediaType="video/mp4"
        className="w-full h-full"
        muted
      />
    </ScreenSharingWrapper>
  );
};

const ScreenSharingWrapper = styled.div`
  ${(props) =>
    props.screenSharingActive
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(props) =>
      props.isMiniPresentationExpanded
        ? css`
            opacity: 0;
            visibility: hidden;
          `
        : css`
            opacity: 1;
            visibility: visible;
          `}
  }
`;
