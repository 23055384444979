import { css } from "styled-components";
import { screens } from "theme";

// Note: Names of constant -> {NAME}_[{MEDIAQUERY}]_{PROPERTY}

/** @typedef {'xs' | 'sm' | 'md' | 'lg' | 'xl'} Breakpoint */

/** @type {Record<Breakpoint, number>} */
export const BREAKPOINTS = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

// Width and height of elements in layout.
export const ASPECT_RATIO = {
  STANDARD_LANDSCAPE: 16 / 9,
  STANDARD_PORTRAIT: 9 / 16,
  COMPACT: 5 / 2, //It refers when the user taps the screen in the chat input and the virtual keyboard is shown.
};

export const PRESENTATION_WIDTH = 960;
export const PRESENTATION_HEIGHT = 540;
export const PRESENTATION_LG_MARGIN = 24;

export const CHAT_FULLSCREEN_WIDTH = 210;

export const DESKTOP_MARGIN = 12;
export const TABLET_MARGIN = 16;
export const MOBILE_MARGIN = 8;

export const MINI_PRESENTATION_WIDTH = 328;
export const MINI_PRESENTATION_HEIGHT = 184;

// Drawings
export const VIEWPORT_DEFAULT_WIDTH = 1440;
export const VIEWPORT_DEFAULT_HEIGHT = 900;
export const STROKE_DEFAULT_WIDTH = 4;

export const PLAYBACK_CONTROLS_FULLSCREEN_LG_WIDTH = (windowSize) =>
  windowSize.width - DESKTOP_MARGIN * 4;
export const PLAYBACK_CONTROLS_FULLSCREEN_MD_WIDTH = (windowSize) =>
  windowSize.width - TABLET_MARGIN * 2;
export const PLAYBACK_CONTROLS_FULLSCREEN_XS_WIDTH = (windowSize) =>
  windowSize.width - MOBILE_MARGIN * 2;

export const PLAYBACK_CONTROLS_LG_HEIGHT = 94;
export const PLAYBACK_CONTROLS_MD_HEIGHT = 40;
export const PLAYBACK_CONTROLS_XS_HEIGHT = 40;

export const TIMELINE_HEIGHT = 4;
export const TIMELINE_INDICATOR_DIAMETER = 12;

export const CHAT_FULLSCREEN_LG_MARGIN_BOTTOM =
  PRESENTATION_LG_MARGIN + PLAYBACK_CONTROLS_LG_HEIGHT + DESKTOP_MARGIN;
export const CHAT_FULLSCREEN_MD_MARGIN_BOTTOM = PLAYBACK_CONTROLS_MD_HEIGHT + TABLET_MARGIN * 2;
export const CHAT_FULLSCREEN_XS_MARGIN_BOTTOM = PLAYBACK_CONTROLS_XS_HEIGHT + MOBILE_MARGIN * 2;

export const PANELS_HEADER_LG_HEIGHT = 52;
export const PANELS_HEADER_MD_HEIGHT = 56;

export const WINDOW_MARGIN = 32;
export const GRID_GAP = 32;
export const PRESENTATION_BUTTON_SIZE = 40;

export const PREVIEW_SLIDES_GAP = 8;
export const PREVIEW_SLIDES_MARGIN = 24;
export const SLIDE_BORDER_SIZE = 8;

export const PRESENTATION_CONTROLS_SIZE = 40;

export const PREVIEW_SLIDES_LG_WIDTH = 256;
export const PREVIEW_SLIDES_MD_WIDTH = (presentationAreaWidth) =>
  presentationAreaWidth - TABLET_MARGIN * 2;
export const PREVIEW_SLIDES_XS_WIDTH = (presentationAreaWidth) =>
  presentationAreaWidth - MOBILE_MARGIN * 2;

export const PREVIEW_SLIDES_LG_MARGIN_BOTTOM =
  PRESENTATION_LG_MARGIN + PLAYBACK_CONTROLS_LG_HEIGHT + DESKTOP_MARGIN;

export const PREVIEW_SLIDES_LG_HEIGHT = (
  presentationAreaHeight,
  fullScreenVisible,
  miniPresentationCollapsed,
  slidesLength
) => {
  // It's necessary know, how many slides exists for calculate the minHeight of slider container and know if we need the scroll.
  const collapsedHeight =
    SLIDE_LG_HEIGHT * slidesLength +
    PREVIEW_SLIDES_MARGIN +
    (slidesLength > 1 ? PREVIEW_SLIDES_GAP : 0);

  const expandedFullscreen =
    presentationAreaHeight -
    PRESENTATION_LG_MARGIN * 2 -
    (miniPresentationCollapsed ? PRESENTATION_BUTTON_SIZE : MINI_PRESENTATION_HEIGHT) -
    PLAYBACK_CONTROLS_LG_HEIGHT -
    DESKTOP_MARGIN * 2;

  const expandedtNotFullscreen = presentationAreaHeight - DESKTOP_MARGIN * 2;

  const inFullscreen = collapsedHeight < expandedFullscreen ? collapsedHeight : expandedFullscreen;

  const notFullscreen =
    collapsedHeight < expandedtNotFullscreen ? collapsedHeight : expandedtNotFullscreen;

  return fullScreenVisible ? inFullscreen : notFullscreen;
};
export const PREVIEW_SLIDES_MD_HEIGHT = (presentationAreaHeight) =>
  presentationAreaHeight - TABLET_MARGIN * 4 - PRESENTATION_BUTTON_SIZE * 2;
export const PREVIEW_SLIDES_XS_HEIGHT = (presentationAreaHeight) =>
  presentationAreaHeight - MOBILE_MARGIN * 4 - PRESENTATION_BUTTON_SIZE * 2;

export const SLIDE_MD_MIN_WIDTH = 240;
export const SLIDE_LANDSCAPE_XS_WIDTH = (presentationAreaHeight) => {
  const heightSlideGrid = PREVIEW_SLIDES_MD_HEIGHT(presentationAreaHeight);
  return heightSlideGrid * ASPECT_RATIO.STANDARD_LANDSCAPE - PREVIEW_SLIDES_MARGIN * 2;
};

export const SLIDE_WIDTH = 232;
export const SLIDE_HEIGHT = 128;

export const SLIDE_LG_HEIGHT =
  (PREVIEW_SLIDES_LG_WIDTH - DESKTOP_MARGIN * 2) / ASPECT_RATIO.STANDARD_LANDSCAPE +
  SLIDE_BORDER_SIZE * 2;
export const SLIDE_PORTRAIT_MD_HEIGHT = (widthFirstSlide) =>
  widthFirstSlide / ASPECT_RATIO.STANDARD_LANDSCAPE + SLIDE_BORDER_SIZE * 2;

// If the current user is presenter, the whiteboard control is going to be visible, so the presentation area is going to get smaller.
export const getPresentationAreaResponsive = ({
  fullScreenVisible,
  isDesktop,
  windowHeight,
  windowWidth,
}) =>
  isDesktop && windowWidth > screens.md
    ? getPresentationArea({
        fullScreenVisible,
        windowHeight,
        windowWidth,
      })
    : getPresentationAreaMD({ fullScreenVisible, windowHeight, windowWidth });

export const getPresentationArea = ({ fullScreenVisible, windowHeight, windowWidth }) => {
  const MARGIN_HORIZONTAL = WINDOW_MARGIN * 2;
  const MARGIN_VERTICAL = WINDOW_MARGIN * 2;

  const getWidth = () => {
    if (fullScreenVisible) return windowWidth;

    return windowWidth - MARGIN_HORIZONTAL - MINI_PRESENTATION_WIDTH - GRID_GAP;
  };

  const getHeight = () => {
    if (fullScreenVisible) return windowHeight;

    return windowHeight - MARGIN_VERTICAL - PLAYBACK_CONTROLS_LG_HEIGHT - GRID_GAP;
  };

  const offsetX = fullScreenVisible ? 0 : WINDOW_MARGIN;
  const offsetY = fullScreenVisible ? 0 : WINDOW_MARGIN;

  return {
    offsetX,
    offsetY,
    width: getWidth(),
    height: getHeight(),
  };
};

export const getPresentationAreaMD = ({ fullScreenVisible, windowHeight, windowWidth }) => {
  const height = fullScreenVisible ? windowHeight : windowWidth / ASPECT_RATIO.STANDARD_LANDSCAPE;

  return {
    offsetX: 0,
    offsetY: 0,
    width: windowWidth,
    height,
  };
};

export const HIDE_SCROLL = css`
  /* Safari, Chrome */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

// This id use for enable mode fullscreen.
export const idWrapperFullscreen = "playback-wrapper-fullscreen";
