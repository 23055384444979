import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { map, distinctUntilChanged } from "rxjs/operators";

import useMediaWidthFitted from "../../hooks/useMediaWidthFitted";

import { eventTimer } from "services/timer";
import { formatTime } from "lib/time";

import { TIMELINE_HEIGHT, TIMELINE_INDICATOR_DIAMETER } from "theme/ui";
import { colors } from "theme";

export const Timeline = () => {
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);
  const duration = useSelector((state) => state.player.duration);

  const [mousePosition, setMousePosition] = useState(0);
  const [currentPageX, setCurrentPageX] = useState(0);
  const [timelinePercentage, setPercentage] = useState(0);
  const [isDragging, setIsDragging] = useState(null);
  const [lastCurrentTime, setLastCurrentTime] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);

  /** @type {React.Ref<HTMLDivElement>} */
  const timeline = useRef(null);
  const getPositionTimeline = useRef({ left: 0, right: 0 });

  /** @type {React.Ref<HTMLDivElement>} */
  const tooltipTime = useRef(null);

  const { isMediaDesktop } = useMediaWidthFitted();

  const changeCurrentTimePosition = useCallback(
    (pageX) => {
      const positionTimeline = getPositionTimeline.current.left;
      const mouseCurrentPosition = pageX - positionTimeline;

      setMousePosition(mouseCurrentPosition);

      const position = (mouseCurrentPosition * 100) / timelineWidth;
      const time = position * (duration / 100);

      setLastCurrentTime(time);

      if (position > 0 && position < 100) {
        setPercentage((time / duration) * 100);
      }
    },
    [duration, timelineWidth]
  );

  // Execute requestSeek after user dragged or clicked the timeline.
  useEffect(() => {
    if (isDragging !== null) {
      if (!isDragging) {
        const timeToSeek = lastCurrentTime < 0 ? 0 : Math.trunc(lastCurrentTime);

        eventTimer.requestSeek(timeToSeek);
      }
    }
  }, [isDragging, lastCurrentTime]);

  const handleSeeking = useCallback(
    (event) => {
      setCurrentPageX(event.pageX);

      if (isDragging) {
        changeCurrentTimePosition(event.pageX);
      }
    },
    [changeCurrentTimePosition, isDragging]
  );

  const setSeeking = useCallback(
    (state, event) => {
      event.preventDefault();

      handleSeeking(event);
      setIsDragging(state);

      setMousePosition(mousePosition);
    },
    [handleSeeking, mousePosition]
  );

  const mouseSeekingHandler = useCallback(
    (event) => {
      if (isDragging !== null) {
        setSeeking(false, event);
      }
    },
    [isDragging, setSeeking]
  );

  function handleTrackHover(clear, event) {
    let position = event.pageX - getPositionTimeline.current.left;

    if (clear) {
      position = 0;
    }

    setMousePosition(position);
  }

  const setCurrentTimelineWidth = useCallback(() => {
    if (timeline.current) {
      setTimelineWidth(timeline.current.clientWidth);

      getPositionTimeline.current = {
        left: timeline.current.getBoundingClientRect().left,
        right: timeline.current.getBoundingClientRect().right,
      };
    }
  }, []);

  /** @param {TouchEvent} event */
  const handleTouchSeeking = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const pageX = event.changedTouches[event.changedTouches.length - 1].pageX;

      if (isDragging) {
        changeCurrentTimePosition(pageX);
      }
    },
    [changeCurrentTimePosition, isDragging]
  );

  const setMobileSeeking = useCallback(
    (state) => {
      setIsDragging(state);

      setMousePosition(mousePosition);
    },
    [mousePosition]
  );

  const mobileTouchSeekingHandler = useCallback(() => {
    if (isDragging !== null) {
      setMobileSeeking(false);
    }
  }, [isDragging, setMobileSeeking]);

  useEffect(() => {
    setCurrentTimelineWidth();

    window.addEventListener("resize", setCurrentTimelineWidth);
    window.addEventListener("mousemove", handleSeeking);
    window.addEventListener("mouseup", mouseSeekingHandler);
    window.addEventListener("touchmove", handleTouchSeeking);
    window.addEventListener("touchend", mobileTouchSeekingHandler);

    return () => {
      window.removeEventListener("resize", setCurrentTimelineWidth);
      window.removeEventListener("mousemove", handleSeeking);
      window.removeEventListener("mouseup", mouseSeekingHandler);
      window.removeEventListener("touchmove", handleTouchSeeking);
      window.removeEventListener("touchend", mobileTouchSeekingHandler);
    };
  }, [
    handleSeeking,
    handleTouchSeeking,
    mobileTouchSeekingHandler,
    mouseSeekingHandler,
    setCurrentTimelineWidth,
  ]);

  useEffect(() => {
    const timeUpdatedSubscription = eventTimer.timeUpdated$
      .pipe(
        map((time) => time),
        distinctUntilChanged()
      )
      .subscribe((time) => {
        setPercentage((time / duration) * 100);
      });

    if (isDragging) {
      timeUpdatedSubscription.unsubscribe();
    }

    return () => {
      timeUpdatedSubscription.unsubscribe();
    };
  }, [duration, isDragging]);

  const tooltipValue = useMemo(() => {
    let time = 0;

    const position = (mousePosition / timelineWidth) * 100;
    time = Math.floor(position * (duration / 100));

    let tooltipCurrentValue = "";
    if (position < 0) {
      tooltipCurrentValue = formatTime(0);
    } else if (position > 100) {
      tooltipCurrentValue = formatTime(duration);
    } else {
      tooltipCurrentValue = formatTime(time);
    }

    return tooltipCurrentValue;
  }, [duration, mousePosition, timelineWidth]);

  const tooltipTimePosition = useMemo(() => {
    let position = 0;

    if (tooltipTime.current) {
      const positionPercentage = (mousePosition / timelineWidth) * 100;

      if (positionPercentage < 0) return;

      const tooltipTimeWidth = tooltipTime.current.offsetWidth;
      const tooltipTimeWidthHalf = tooltipTimeWidth / 2;

      const tooltipLimitRight =
        getPositionTimeline.current.right - tooltipTimeWidth + tooltipTimeWidth / 2;

      if (mousePosition >= 0 && mousePosition <= tooltipTimeWidth / 2) {
        position = 0;
      } else if (
        (currentPageX >= tooltipLimitRight && currentPageX <= getPositionTimeline.current.right) ||
        positionPercentage >= 100
      ) {
        position = tooltipLimitRight - getPositionTimeline.current.left - tooltipTimeWidthHalf;
      } else {
        position = mousePosition - tooltipTimeWidth / 2;
      }
    }

    return position;
  }, [mousePosition, timelineWidth, currentPageX]);

  const shouldShowTooltip = (isDragging, isOnDesktop) => {
    if (isDragging) {
      return true;
    }

    if (isOnDesktop && isDragging) {
      return true;
    }
    if (!isOnDesktop && isDragging) {
      return true;
    }
    if (!isOnDesktop && !isDragging) {
      return false;
    }
  };

  return (
    <TimelineWrapper
      ref={timeline}
      className="w-full relative rounded-md"
      style={{ height: TIMELINE_HEIGHT }}
      onMouseMove={(event) => handleTrackHover(false, event)}
      onMouseDown={(event) => setSeeking(true, event)}
      onTouchStart={() => setMobileSeeking(true)}
      isDragging={isDragging}
      isOnDesktop={isMediaDesktop}
      fullScreenVisible={fullScreenVisible}
    >
      <TimelineBar className="rounded absolute select-none" percentage={timelinePercentage}>
        <TimelineIndicator className="absolute" isDragging={isDragging} />
      </TimelineBar>
      <TimelineTooltip
        ref={tooltipTime}
        hoverTimePosition={tooltipTimePosition}
        className="flex justify-center items-center absolute left-0 bg-black text-white text-12 rounded-xxl mb-4 py-2 px-4 select-none"
        shouldBeVisible={shouldShowTooltip(isDragging, isMediaDesktop)}
      >
        {tooltipValue}
      </TimelineTooltip>
    </TimelineWrapper>
  );
};

const TimelineBar = styled.div`
  width: ${(props) => props.percentage}%;
  height: 100%;
  top: 0;
  left: 0;
`;

const TimelineIndicator = styled.div`
  width: ${TIMELINE_INDICATOR_DIAMETER}px;
  height: ${TIMELINE_INDICATOR_DIAMETER}px;
  border-radius: 50%;

  top: 50%;
  right: -${TIMELINE_INDICATOR_DIAMETER / 2}px;
  transform: translateY(-50%);

  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.isDragging
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  transition: opacity 0.15s, visibility 0.15s;
`;

const TimelineTooltip = styled.div`
  bottom: 100%;

  transform: translateX(${(props) => props.hoverTimePosition}px);
  transition: opacity 0.15s, visibility 0.15s;

  ${(props) =>
    props.shouldBeVisible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const TimelineWrapper = styled.div`
  ${(props) =>
    props.fullScreenVisible
      ? css`
          background-color: ${colors.white50};

          ${TimelineBar}, ${TimelineIndicator} {
            background-color: ${colors.white};
          }
        `
      : css`
          background-color: ${colors.black12};

          ${TimelineBar}, ${TimelineIndicator} {
            background-color: ${colors.black};
          }
        `};

  ${(props) =>
    props.isOnDesktop &&
    css`
      &:hover {
        ${TimelineIndicator} {
          opacity: 1;
          visibility: visible;
        }

        ${TimelineTooltip} {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.15s, visibility 0.15s;
        }
      }
    `}

  ${(props) =>
    props.isDragging
      ? css`
          cursor: grabbing;
        `
      : css`
          cursor: pointer;
        `}
`;
