import findLast from "lodash.findlast";

/**
 *
 * @param {PlaybackData} data
 * @param {Number} time
 */
export const getDrawingsAtTime = (data, time) => {
  return data.drawings.filter((drawing) => drawing.time <= time);
};
