import React from "react";

export const LineDrawing = ({ dataDrawing, drawingOffset, strokeWidthScaleFactor, zoomFactor }) => {
  const { lineCoordinates } = dataDrawing;

  return (
    <line
      x1={(lineCoordinates.x1 + drawingOffset.x) * zoomFactor}
      y1={(lineCoordinates.y1 + drawingOffset.y) * zoomFactor}
      x2={(lineCoordinates.x2 + drawingOffset.x) * zoomFactor}
      y2={(lineCoordinates.y2 + drawingOffset.y) * zoomFactor}
      className={`stroke-palette-${dataDrawing.color} fill-transparent`}
      strokeWidth={strokeWidthScaleFactor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};
