import findLast from "lodash.findlast";
import get from "lodash.get";

/**
 *
 * @param {import("./data").PlaybackData} data
 * @param {Number} time
 */
export const getSlideInteractionForTime = (data, time) => {
  const interactions = get(data.slides, "interactions", []);
  const interaction = findLast(interactions, (interaction) => time >= interaction.time);
  return interaction || null;
};

/**
 *
 * @param {import("./data").PlaybackData} data
 */
export const getSlideTumbnails = (data) => {
  /**
   * @type {import("./data").PlaybackDataSlidesInteraction[]}
   */
  const interactions = get(data.slides, "interactions", []);
  /**
   * @type {import("./data").PlaybackDataSlidesMap[]}
   */
  const slides = get(data.slides, "slides", []);

  const thumbnails = [];
  interactions.forEach((interaction) => {
    const { time, fileId, slideIndex, isSlideChange } = interaction;
    if (isSlideChange) {
      const slide = slides.find((s) => s.fileId === fileId && s.index === slideIndex);
      const { thumb, width, height } = slide;

      const thumbnail = {
        time,
        thumb,
        width,
        height,
        fileId,
        slideIndex,
      };

      thumbnails.push(thumbnail);
    }
  });

  return thumbnails;
};
