import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { IntlProvider } from "react-intl";

import { getDefaultLocale } from "./lib/locale";
import enMessages from "./i18n/en.json";
import esMessages from "./i18n/es.json";

import { AppWrapper } from "components/AppWrapper";

import { Provider } from "react-redux";
import { store } from "./store";

const messages = { en: enMessages, es: esMessages };

function App() {
  const [locale, setLocale] = useGlobal("locale");

  useEffect(() => {
    setLocale(getDefaultLocale());
  }, [setLocale]);

  return locale ? (
    <Provider store={store}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <AppWrapper locale={locale} />
      </IntlProvider>
    </Provider>
  ) : null;
}

export default App;
