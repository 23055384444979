import UAParser from "ua-parser-js";
import { BROWSER_TYPES, DEVICE_MODELS, DEVICE_OS_NAMES, DEVICES_TYPE } from "lib/devices";

const parser = new UAParser();
const { type, model } = parser.getDevice();
const { name } = parser.getOS();

const deviceModel = model === undefined ? undefined : model.toUpperCase();
const deviceType = type === undefined ? undefined : type.toUpperCase();

/**
 * Possible 'os.name' by https://github.com/faisalman/ua-parser-js
 * AIX, Amiga OS, Android, Arch, Bada, BeOS, BlackBerry, CentOS, Chromium OS, Contiki,
 * Fedora, Firefox OS, FreeBSD, Debian, DragonFly, Gentoo, GNU, Haiku, Hurd, iOS,
 * Joli, Linpus, Linux, Mac OS, Mageia, Mandriva, MeeGo, Minix, Mint, Morph OS, NetBSD,
 * Nintendo, OpenBSD, OpenVMS, OS/2, Palm, PCLinuxOS, Plan9, Playstation, QNX, RedHat,
 * RIM Tablet OS, RISC OS, Sailfish, Series40, Slackware, Solaris, SUSE, Symbian, Tizen,
 * Ubuntu, UNIX, VectorLinux, WebOS, Windows [Phone/Mobile], Zenwalk
 */
const nameOS = name.toUpperCase();

/**
 * Possible browser by https://github.com/faisalman/ua-parser-js
 * Amaya, Android Browser, Arora, Avant, Baidu, Blazer, Bolt, Camino, Chimera, Chrome,
 * Chromium, Comodo Dragon, Conkeror, Dillo, Dolphin, Doris, Edge, Epiphany, Fennec,
 * Firebird, Firefox, Flock, GoBrowser, iCab, ICE Browser, IceApe, IceCat, IceDragon,
 * Iceweasel, IE [Mobile], Iron, Jasmine, K-Meleon, Konqueror, Kindle, Links,
 * Lunascape, Lynx, Maemo, Maxthon, Midori, Minimo, MIUI Browser, [Mobile] Safari,
 * Mosaic, Mozilla, Netfront, Netscape, NetSurf, Nokia, OmniWeb, Opera [Mini/Mobi/Tablet],
 * Phoenix, Polaris, QQBrowser, RockMelt, Silk, Skyfire, SeaMonkey, SlimBrowser, Swiftfox,
 * Tizen, UCBrowser, Vivaldi, w3m, Yandex
 */
const browser = parser.getBrowser();
let browserName = browser.name.toUpperCase();
browserName = browserName.replace(/MOBILE/g, "").trim();

export const isChrome = browserName === BROWSER_TYPES.CHROME;
export const isEdge = browserName === BROWSER_TYPES.EDGE;
export const isFirefox = browserName === BROWSER_TYPES.FIREFOX;
export const isSafari = browserName === BROWSER_TYPES.SAFARI;
export const anotherBrowser = !Object.values(BROWSER_TYPES).some((b) => b === browserName);

// On desktop the type value always is undefined.
export const isDesktop = deviceType === DEVICES_TYPE.DESKTOP || false;
export const isTablet = deviceType === undefined ? false : deviceType === DEVICES_TYPE.TABLET;
export const isMobile = deviceType === undefined ? false : deviceType === DEVICES_TYPE.MOBILE;

export const isIOS = (isTablet || isMobile) && nameOS === DEVICE_OS_NAMES.IOS;
export const isIpad =
  isTablet && nameOS === DEVICE_OS_NAMES.IOS && deviceModel === DEVICE_MODELS.IPAD;
export const isIphone =
  isMobile && nameOS === DEVICE_OS_NAMES.IOS && deviceModel === DEVICE_MODELS.IPHONE;

export const isAndroid = (isTablet || isMobile) && nameOS === DEVICE_OS_NAMES.ANDROID;

export const hasBrowserTouchScreen =
  "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export const hasBrowserFullscreen = document.exitFullscreen || document.webkitExitFullscreen;
