import { DRAWING_TYPE, DRAWING_UNDOREDO } from "./drawings";

export const RECORDING_HISTORY_TYPE = {
  ...DRAWING_TYPE,
  ...DRAWING_UNDOREDO,
  RESIZING: "RESIZING",
  MOVING: "MOVING",
  BRING_FORWARD: "BRING_FORWARD",
  BRING_TO_FRONT: "BRING_TO_FRONT",
  SEND_BACKWARD: "SEND_BACKWARD",
  SEND_TO_BACK: "SEND_TO_BACK",
  DELETE_DRAWING: "DELETE_DRAWING",
  CLEAR_WHITEBOARD: "CLEAR_WHITEBOARD",
  CHANGE_SLIDE: "CHANGE_SLIDE",
  PRESENT_VIDEO: "PRESENT_VIDEO",
  STOP_VIDEO_PRESENTATION: "STOP_VIDEO_PRESENTATION",
};
