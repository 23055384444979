import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, distinctUntilChanged } from "rxjs/operators";
import styled from "styled-components";
import get from "lodash.get";
import classNames from "classnames";
import { useIntl } from "react-intl";

import { IconClickeable } from "components/elements/IconClickeable";
import { Timeline } from "components/Timeline";
import { VolumenRange } from "./VolumenRange";

import { eventTimer } from "services/timer";

import { playerActions } from "store/player";

import useWindowSize from "hooks/useWindowSize";

import { hasBrowserFullscreen } from "lib/apis/agent";
import { handleToggleFulScreen } from "lib/apis/fullscreen";
import { MEDIA_ELEMENT_NAMES } from "lib/player";

import {
  BREAKPOINTS,
  PLAYBACK_CONTROLS_LG_HEIGHT,
  PLAYBACK_CONTROLS_FULLSCREEN_LG_WIDTH,
  PLAYBACK_CONTROLS_FULLSCREEN_MD_WIDTH,
  PLAYBACK_CONTROLS_FULLSCREEN_XS_WIDTH,
  PLAYBACK_CONTROLS_MD_HEIGHT,
} from "theme/ui";

const TIME_TO_FORWARD = 15;
const TIME_TO_REWIND = 15;

const canPlay = true;

export const PlaybackControls = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const volume = useSelector((state) => get(state, "player.volume"));
  const muted = useSelector((state) => get(state, "player.muted"));
  const duration = useSelector((state) => state.player.duration);

  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);
  const chatActivated = useSelector((state) => state.player.chatActivated);
  const isPlaying = useSelector((state) => state.player.playing);

  const [currentTime, setCurrentTime] = useState(0);

  const windowSize = useWindowSize();

  useEffect(() => {
    const timeUpdatedSubscription = eventTimer.timeUpdated$
      .pipe(
        map((time) => Math.floor(time)),
        distinctUntilChanged()
      )
      .subscribe((time) => setCurrentTime(time));

    return () => timeUpdatedSubscription.unsubscribe();
  }, []);

  const secondsToTimeString = (seconds) => {
    const formatWithLeadingZeros = (number) => (number < 10 ? `0${number}` : number);
    const hourInSeconds = 3600;
    const hourInMinutes = 60;
    const minuteInSeconds = 60;

    const hoursNumber = Math.floor(seconds / hourInSeconds);
    const minutesNumber = Math.floor(seconds / minuteInSeconds - hoursNumber * hourInMinutes);
    const secondsNumber = seconds - minutesNumber * minuteInSeconds - hoursNumber * hourInSeconds;

    const mmss = `${formatWithLeadingZeros(minutesNumber)}:${formatWithLeadingZeros(
      secondsNumber
    )}`;
    return duration >= hourInSeconds ? `${formatWithLeadingZeros(hoursNumber)}:${mmss}` : mmss;
  };

  const onPlayOrPause = () => {
    // Restart at the beginning
    if (currentTime >= duration) {
      eventTimer.requestSeek(0);

      dispatch(playerActions.play());
    } else {
      if (isPlaying) eventTimer.requestPause();
      else eventTimer.requestPlay();

      dispatch(playerActions.playingChanged());
    }
  };

  const handleRewind = () => {
    const rewindSeconds = currentTime - TIME_TO_REWIND;
    const time = rewindSeconds < 0 ? 0 : rewindSeconds;

    eventTimer.requestSeek(time);
  };

  const handleForward = () => {
    const forwardSeconds = currentTime + TIME_TO_FORWARD;
    const time = forwardSeconds > duration ? duration : forwardSeconds;

    eventTimer.requestSeek(time);
  };

  return (
    <PlaybackControlsWrapper
      className={classNames(
        "md:flex flex-col bg-white shadow-all rounded-xxl px-4 py-0 md:px-10 md:py-8",
        {
          "flex bg-black absolute bottom-0 left-0 m-4 xs:m-8 md:m-12 mt-0": fullScreenVisible,
        },
        { "hidden relative": !fullScreenVisible }
      )}
      fullScreenVisible={fullScreenVisible}
      windowSize={windowSize}
    >
      <div className="w-full h-full flex gap-4 xs:gap-8 items-center justify-between">
        <IconClickeable
          disableHover
          size="sm"
          icon={IconClickeable.types.CHAT_OFF}
          activeIcon={IconClickeable.types.CHAT_ON}
          active={chatActivated}
          fill={fullScreenVisible ? "white" : "black"}
          activeFill={fullScreenVisible ? "white" : "black"}
          hoverFill={fullScreenVisible ? "white" : "black"}
          activeHoverFill={fullScreenVisible ? "white" : "black"}
          className="flex md:hidden"
          onClick={() => dispatch(playerActions.chatActivatedChanged())}
        />
        <div className="w-full flex items-center">
          <div className={`text-12 text-left ${fullScreenVisible ? "text-white" : "text-black "}`}>
            {secondsToTimeString(currentTime)}
          </div>
          <div className="w-full flex flex-col mx-12">
            <Timeline />
          </div>
          <div className={`text-12 text-right ${fullScreenVisible ? "text-white" : "text-black "}`}>
            {secondsToTimeString(Math.abs(duration - currentTime))}
          </div>
        </div>
        {hasBrowserFullscreen && (
          <IconClickeable
            disableHover
            size="sm"
            icon={IconClickeable.types.FULL_SCREEN}
            activeIcon={IconClickeable.types.EXIT_FULL_SCREEN}
            active={fullScreenVisible}
            fill="white"
            activeFill="white"
            className="flex md:hidden"
            onClick={() => {
              handleToggleFulScreen(fullScreenVisible);
            }}
          />
        )}
      </div>
      <div className="hidden md:flex items-center justify-between mt-10">
        <div className="flex">
          <IconClickeable
            size="sm"
            icon={IconClickeable.types.CHAT_OFF}
            activeIcon={IconClickeable.types.CHAT_ON}
            active={chatActivated}
            label={intl.formatMessage({
              id: "presentation.chat.show.label",
            })}
            activeLabel={intl.formatMessage({
              id: "presentation.chat.hide.label",
            })}
            fill={fullScreenVisible ? "white" : "black"}
            activeFill={fullScreenVisible ? "white" : "black"}
            hoverFill={fullScreenVisible ? "white" : "black"}
            activeHoverFill={fullScreenVisible ? "white" : "black"}
            onClick={() => dispatch(playerActions.chatActivatedChanged())}
          />
          <div className="flex items-center ml-16 gap-4">
            <IconClickeable
              size="sm"
              icon={IconClickeable.types.VOLUME_HIGH}
              activeIcon={IconClickeable.types.VOLUME_MUTE}
              active={muted}
              label="Mute volume"
              activeLabel="Unmute volume"
              fill={fullScreenVisible ? "white" : "black"}
              activeFill={fullScreenVisible ? "white" : "black"}
              hoverFill={fullScreenVisible ? "white" : "black"}
              activeHoverFill={fullScreenVisible ? "white" : "black"}
              onClick={() => {
                dispatch(playerActions.muteToggled());

                /** @type {HTMLVideoElement} */
                const audioEl = document.getElementById(MEDIA_ELEMENT_NAMES.AUDIO);
                if (audioEl) {
                  audioEl.volume = muted ? volume : 0;
                }
              }}
            />
            <VolumenRange />
          </div>
        </div>
        <div className="flex">
          <IconClickeable
            size="sm"
            icon={IconClickeable.types.FAST_REWIND}
            disabled={!canPlay}
            label="Rewind"
            fill={fullScreenVisible ? "white" : "black"}
            hoverFill={fullScreenVisible ? "white" : "black"}
            onClick={handleRewind}
          />
          <IconClickeable
            size="sm"
            icon={IconClickeable.types.PLAY}
            activeIcon={IconClickeable.types.PAUSE}
            active={isPlaying}
            label="Play"
            activeLabel="Pause"
            fill={fullScreenVisible ? "white" : "black"}
            activeFill={fullScreenVisible ? "white" : "black"}
            hoverFill={fullScreenVisible ? "white" : "black"}
            activeHoverFill={fullScreenVisible ? "white" : "black"}
            className="mx-16"
            onClick={onPlayOrPause}
          />
          <IconClickeable
            size="sm"
            icon={IconClickeable.types.FAST_FORWARD}
            disabled={!canPlay}
            label="Forward"
            fill={fullScreenVisible ? "white" : "black"}
            hoverFill={fullScreenVisible ? "white" : "black"}
            onClick={handleForward}
          />
        </div>
        <div className="flex">
          {hasBrowserFullscreen && (
            <IconClickeable
              size="sm"
              icon={IconClickeable.types.FULL_SCREEN}
              activeIcon={IconClickeable.types.EXIT_FULL_SCREEN}
              active={fullScreenVisible}
              label="Enter full screen"
              activeLabel="Exit full screen"
              fill={fullScreenVisible ? "white" : "black"}
              activeFill={fullScreenVisible ? "white" : "black"}
              hoverFill={fullScreenVisible ? "white" : "black"}
              activeHoverFill={fullScreenVisible ? "white" : "black"}
              onClick={() => {
                handleToggleFulScreen(fullScreenVisible);
              }}
            />
          )}
        </div>
      </div>
    </PlaybackControlsWrapper>
  );
};

export const PlaybackControlsWrapper = styled.div`
  width: ${(props) =>
    props.fullScreenVisible
      ? `${PLAYBACK_CONTROLS_FULLSCREEN_LG_WIDTH(props.windowSize)}px`
      : "100%"};
  height: ${PLAYBACK_CONTROLS_LG_HEIGHT}px;

  grid-area: controls;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: ${(props) => `${PLAYBACK_CONTROLS_FULLSCREEN_MD_WIDTH(props.windowSize)}px`};
    height: ${PLAYBACK_CONTROLS_MD_HEIGHT}px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: ${(props) => `${PLAYBACK_CONTROLS_FULLSCREEN_XS_WIDTH(props.windowSize)}px`};
  }
`;
