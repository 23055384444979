import { createSlice } from "@reduxjs/toolkit";

import { PANEL_NAMES } from "lib/panels";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    fullScreenVisible: false,
    meetingId: null,
    isMiniPresentationExpanded: false,
    miniPresentationCollapsed: false,
    panelActiveName: PANEL_NAMES.FILES,
    presentationControlsVisible: false,
    screenSharingActive: false,
    videoSharingActive: false,
    isPresentingCamera: false,
    isLoadingCamera: false,
    isLoadingScreenShare: false,
  },
  reducers: {
    presentationToggled: (state) => {
      state.isMiniPresentationExpanded = !state.isMiniPresentationExpanded;
      state.miniPresentationCollapsed = false;
    },
    initialized: (state, action) => {
      state.meetingId = action.payload;
    },
    fullScreenVisibleToggled: (state) => {
      state.fullScreenVisible = !state.fullScreenVisible;
    },
    presentationControlsToggled: (state) => {
      state.presentationControlsVisible = !state.presentationControlsVisible;
    },
    miniPresentationCollapsedToggled: (state) => {
      state.miniPresentationCollapsed = !state.miniPresentationCollapsed;
    },
    miniPresentationDontCollapsed: (state) => {
      state.miniPresentationCollapsed = false;
    },
    screenSharingActivated: (state) => {
      state.screenSharingActive = true;
    },
    screenSharingDesactivated: (state) => {
      state.screenSharingActive = false;
    },
    videoSharingActivated: (state) => {
      state.videoSharingActive = true;
    },
    videoSharingDesactivated: (state) => {
      state.videoSharingActive = false;
    },
    panelActiveNameChanged: (state, action) => {
      state.panelActiveName = action.payload;
    },
    isPresentingCameraUpdated: (state, action) => {
      state.isPresentingCamera = action.payload;
    },
    isLoadingCameraUpdated: (state, action) => {
      state.isLoadingCamera = action.payload;
    },
    isLoadingScreenShareUpdated: (state, action) => {
      state.isLoadingScreenShare = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;
export const navigationReducer = navigationSlice.reducer;
