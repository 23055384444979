import { useEffect, useState } from "react";

import { eventTimer } from "services/timer";

export const useThumbnailSelected = ({ thumbnail }) => {
  const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);

  useEffect(() => {
    const subscription = eventTimer.slides$.subscribe((interaction) => {
      if (interaction) {
        if (thumbnail.time === interaction.time) setIsThumbnailSelected(true);
        else setIsThumbnailSelected(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [thumbnail]);

  return { isThumbnailSelected };
};
