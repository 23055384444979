import React, { useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import styled, { css } from "styled-components";

import { WhiteboardDrawing } from "./WhiteboardDrawing";
import { FreePen } from "./FreePen";

import { usePresentationAreaSize } from "hooks/usePresentationAreaSize";
import { useWhiteboardPointer } from "hooks/whiteboard/useWhiteboardPointer";
import { useWhiteboardSlides } from "hooks/whiteboard/useWhiteboardSlides";

import { getCurrentSlideImageType } from "lib/files";

import {
  BREAKPOINTS,
  MINI_PRESENTATION_HEIGHT,
  MINI_PRESENTATION_WIDTH,
  STROKE_DEFAULT_WIDTH,
  VIEWPORT_DEFAULT_HEIGHT,
  VIEWPORT_DEFAULT_WIDTH,
} from "theme/ui";

export const WhiteboardPresentation = () => {
  const presentationArea = usePresentationAreaSize();
  const { pointerCoordinates } = useWhiteboardPointer();

  const slides = useSelector((state) => state.slides.slides);
  const drawings = useSelector((state) => state.drawings.drawings);
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);
  const isMiniPresentationExpanded = useSelector(
    (state) => state.navigation.isMiniPresentationExpanded
  );

  /** @type {React.Ref<SVGElement>} */
  const imageBoxSVG = useRef(null);

  const { coordinates, currentSlide, zoom } = useWhiteboardSlides({ slides });

  const [panningOffset, setPanningOffset] = useState({ x: 0, y: 0 });

  const currentSlideWidth = currentSlide ? currentSlide.width : 0;
  const currentSlideHeight = currentSlide ? currentSlide.height : 0;

  const dominantAxis = useMemo(() => {
    const presentationAspectRatio = presentationArea.width / presentationArea.height;
    const slideAspectRatio = currentSlideWidth / currentSlideHeight;

    if (
      (currentSlideWidth > currentSlideHeight && presentationAspectRatio < slideAspectRatio) ||
      presentationAspectRatio < slideAspectRatio
    )
      return "horizontal";
    else return "vertical";
  }, [currentSlideHeight, currentSlideWidth, presentationArea]);

  const strokeWidthScaleFactor = useMemo(() => {
    const width = isMiniPresentationExpanded ? MINI_PRESENTATION_WIDTH : presentationArea.width;
    const height = isMiniPresentationExpanded ? MINI_PRESENTATION_HEIGHT : presentationArea.height;

    if (dominantAxis === "horizontal")
      return (width / VIEWPORT_DEFAULT_WIDTH) * STROKE_DEFAULT_WIDTH;

    return (height / VIEWPORT_DEFAULT_HEIGHT) * STROKE_DEFAULT_WIDTH;
  }, [dominantAxis, isMiniPresentationExpanded, presentationArea]);

  const zoomFactor = useMemo(() => {
    const width = isMiniPresentationExpanded ? MINI_PRESENTATION_WIDTH : presentationArea.width;
    const height = isMiniPresentationExpanded ? MINI_PRESENTATION_HEIGHT : presentationArea.height;

    if (dominantAxis === "horizontal") return width / currentSlideWidth;
    return height / currentSlideHeight;
  }, [
    currentSlideHeight,
    currentSlideWidth,
    dominantAxis,
    isMiniPresentationExpanded,
    presentationArea,
  ]);

  /**
   * Returns a object with { x, y, width, height } imageBox attributes.
   * The X and Y properties are always zero (0).
   * @type {useMemo}
   * @returns {Object}
   */
  const imageBox = useMemo(() => {
    const width = currentSlideWidth * zoomFactor;
    const height = currentSlideHeight * zoomFactor;
    return {
      x: 0,
      y: 0,
      width,
      height,
    };
  }, [currentSlideHeight, currentSlideWidth, zoomFactor]);

  /**
   * invertedNumber
   * @param {Number} Number
   * @returns {Number}
   */
  const invertedNumber = (number) => 0 - number;

  /**
   * viewBox
   * Returns a object with { x, y, width, height } viewBox attributes.
   *
   * This object has different variants on recognized systems such as mobile and tablet.
   *
   * Previously there was a bug that pushed panning extensively. This bug is still present on any
   * device with a small screen, but not on mobile phones and tablets.
   * Although it sounds strange: Small screen !== Mobile or tablet.
   * We use useUserAgent() to detect device type.
   *
   * @see {@link https://github.com/Tutoom/tutoom-web-app/issues/344 Panning Issue}
   *
   * @type {useMemo}
   * @returns {Object}
   */
  const viewBox = useMemo(() => {
    const width = isMiniPresentationExpanded ? MINI_PRESENTATION_WIDTH : presentationArea.width;
    const height = isMiniPresentationExpanded ? MINI_PRESENTATION_HEIGHT : presentationArea.height;
    const scaledPresentationAreaWidth = width / zoom;
    const scaledPresentationAreaHeight = height / zoom;

    let x = coordinates.x + (imageBox.width - scaledPresentationAreaWidth) / 2;
    let y = coordinates.y + (imageBox.height - scaledPresentationAreaHeight) / 2;

    let imageBoxScaledPresentationDifferenceHeight = imageBox.height - scaledPresentationAreaHeight;
    let imageBoxScaledPresentationY = y - imageBoxScaledPresentationDifferenceHeight;

    let imageBoxScaledPresentationDifferenceWidth = imageBox.width - scaledPresentationAreaWidth;
    let imageBoxScaledPresentationX = x - imageBoxScaledPresentationDifferenceWidth;

    /**
     * `panningOffset` avoid exagerated panning and showing gray borders in presentation area.
     * Check the following issues related to this code:
     * @see {@link https://github.com/Tutoom/tutoom-web-app/issues/344 Panning Issue}
     * @see {@link https://github.com/Tutoom/tutoom-web-app/issues/854 Gray borders in Presentation Area}
     */
    let calculatedPanningOffset = { x: 0, y: 0 };

    if (imageBoxScaledPresentationDifferenceWidth < 0) {
      calculatedPanningOffset.x =
        imageBox.width -
        scaledPresentationAreaWidth -
        (coordinates.x + (imageBox.width - scaledPresentationAreaWidth));
    } else {
      if (imageBoxScaledPresentationX > 0) {
        calculatedPanningOffset.x = invertedNumber(imageBoxScaledPresentationX);
      }
      if (x < 0) {
        calculatedPanningOffset.x = invertedNumber(x);
      }
    }

    if (imageBoxScaledPresentationDifferenceHeight < 0) {
      calculatedPanningOffset.y =
        imageBox.height -
        scaledPresentationAreaHeight -
        (coordinates.y + (imageBox.height - scaledPresentationAreaHeight));
    } else {
      if (imageBoxScaledPresentationY > 0) {
        calculatedPanningOffset.y = invertedNumber(imageBoxScaledPresentationY);
      }
      if (y < 0) {
        calculatedPanningOffset.y = invertedNumber(y);
      }
    }

    // Update X and Y for viewBox result.
    x = x + calculatedPanningOffset.x;
    y = y + calculatedPanningOffset.y;

    // Update the panningOffset state for red pointer indicator and drawingOffset.
    setPanningOffset({ x: calculatedPanningOffset.x, y: calculatedPanningOffset.y });

    return {
      x,
      y,
      width: scaledPresentationAreaWidth,
      height: scaledPresentationAreaHeight,
    };
  }, [
    coordinates.x,
    coordinates.y,
    imageBox.height,
    imageBox.width,
    isMiniPresentationExpanded,
    presentationArea.height,
    presentationArea.width,
    setPanningOffset,
    zoom,
  ]);

  const viewBoxStr = `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`;

  const compensationDiff = useMemo(
    () => ({
      x: coordinates.x + (imageBox.width - viewBox.width) / 2,
      y: coordinates.y + (imageBox.height - viewBox.height) / 2,
    }),
    [imageBox, viewBox, coordinates]
  );

  const drawingOffset = useMemo(
    () => ({
      x: compensationDiff.x - viewBox.x + panningOffset.x,
      y: compensationDiff.y - viewBox.y + panningOffset.y,
    }),
    [compensationDiff.x, compensationDiff.y, panningOffset.x, panningOffset.y, viewBox.x, viewBox.y]
  );

  return (
    <WhiteboardDrawingWrapper
      className={classNames(
        "w-full h-full bg-black25 rounded-none select-none",
        { "md:rounded-none": fullScreenVisible },
        { "md:rounded-xxl": !fullScreenVisible }
      )}
      isMiniPresentationExpanded={isMiniPresentationExpanded}
    >
      {currentSlide !== null && (
        <svg
          ref={imageBoxSVG}
          width={isMiniPresentationExpanded ? MINI_PRESENTATION_WIDTH : presentationArea.width}
          height={isMiniPresentationExpanded ? MINI_PRESENTATION_HEIGHT : presentationArea.height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBoxStr}
        >
          <g>
            <foreignObject
              x={imageBox.x}
              y={imageBox.y}
              width={imageBox.width}
              height={imageBox.height}
            >
              <picture>
                <source srcSet={currentSlide.webp} type="image/webp" />
                <source
                  srcSet={currentSlide.source}
                  type={getCurrentSlideImageType(currentSlide.extension)}
                />
                <img src={currentSlide.source} width="100%" alt="" id="imagebox-img" />
              </picture>
            </foreignObject>
          </g>

          <FreePen zoomFactor={zoomFactor} drawingOffset={drawingOffset} />

          {drawings.length > 0 && (
            <g>
              {drawings.map((drawingElement, index) => (
                <WhiteboardDrawing
                  key={index}
                  drawing={drawingElement}
                  drawingId={drawingElement.time}
                  drawingOffset={drawingOffset}
                  zoomFactor={zoomFactor}
                  zoom={zoom}
                  viewBox={viewBox}
                  strokeWidthScaleFactor={strokeWidthScaleFactor}
                />
              ))}
            </g>
          )}
        </svg>
      )}
    </WhiteboardDrawingWrapper>
  );
};

const WhiteboardDrawingWrapper = styled.div`
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(props) =>
      props.isMiniPresentationExpanded
        ? css`
            opacity: 0;
            visibility: hidden;
          `
        : css`
            opacity: 1;
            visibility: visible;
          `}
  }
`;
