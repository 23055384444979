import { useEffect, useState } from "react";

import { eventTimer } from "services/timer";

export const useVideoSharing = () => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    const subscription = eventTimer.videoSharings$.subscribe((videoSharing) => {
      if (videoSharing) setVideo(videoSharing);
      else setVideo(null);
    });

    return () => subscription.unsubscribe();
  }, []);

  return { video };
};
