import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useUserAgent } from "hooks/useUserAgent";

import { getPresentationAreaResponsive } from "theme/ui";

export const usePresentationAreaSize = () => {
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);

  const { windowWidth, windowHeight, isDesktop } = useUserAgent();

  return useMemo(
    () =>
      getPresentationAreaResponsive({
        fullScreenVisible,
        isDesktop,
        windowHeight,
        windowWidth,
      }),
    [fullScreenVisible, isDesktop, windowHeight, windowWidth]
  );
};
