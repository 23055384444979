import React from "react";
import { useIntl } from "react-intl";

import { AppLoader } from "components/elements/AppLoader";

export const PlaybackLoader = () => {
  const intl = useIntl();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0">
      <AppLoader />
      <div className="text-center tracking-100 p-10 select-none">
        {intl.formatMessage({ id: "loaders.meeting.text" })}
      </div>
    </div>
  );
};
