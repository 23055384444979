import React, { useEffect, useRef } from "react";
import Proptypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";

export const TOOLTIP_POSITION = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGTH: "right",
};

export const AppTooltip = ({ id, text, position = TOOLTIP_POSITION.BOTTOM, margin = 8 }) => {
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);

  /** @type {React.Ref<HTMLDivElement>} */
  const tooltipRef = useRef(null);

  /**
   *
   * @param {HTMLDivElement} element
   */
  const getPosition = (element) => {
    if (!tooltipRef.current) return;
    if (element) {
      const { top, left, width, height, bottom } = element.getBoundingClientRect();

      switch (position) {
        case TOOLTIP_POSITION.BOTTOM: {
          tooltipRef.current.style.top = bottom + margin + "px";
          tooltipRef.current.style.left =
            left - tooltipRef.current.clientWidth / 2 + width / 2 + "px";
          break;
        }
        case TOOLTIP_POSITION.LEFT: {
          tooltipRef.current.style.top =
            top - tooltipRef.current.clientHeight / 2 + height / 2 + "px";
          tooltipRef.current.style.left = left - tooltipRef.current.clientWidth - margin + "px";
          break;
        }
        case TOOLTIP_POSITION.RIGTH: {
          tooltipRef.current.style.top =
            top - tooltipRef.current.clientHeight / 2 + height / 2 + "px";
          tooltipRef.current.style.left = left + width + margin + "px";
          break;
        }
        default: {
          tooltipRef.current.style.top = top - tooltipRef.current.clientHeight - margin + "px";
          tooltipRef.current.style.left =
            left - tooltipRef.current.clientWidth / 2 + width / 2 + "px";
          break;
        }
      }
    }
  };

  useEffect(() => {
    const element = document.querySelector(`[data-tooltip-id='${id}']`);
    if (!element) return;

    const enter = () => {
      if (!tooltipRef.current) return;

      getPosition(element);
      tooltipRef.current.style.opacity = 1;
      tooltipRef.current.style.visibility = "visible";
    };
    const leave = () => {
      if (!tooltipRef.current) return;

      tooltipRef.current.style.opacity = 0;
      tooltipRef.current.style.visibility = "hidden";
    };

    element.addEventListener("mouseenter", enter);
    element.addEventListener("mouseleave", leave);
    element.addEventListener("resize", leave);
    document.addEventListener("fullscreenchange", leave);
    document.addEventListener("webkitfullscreenchange", leave);

    return () => {
      element.removeEventListener("mouseenter", enter);
      element.removeEventListener("mouseleave", leave);
      element.addEventListener("resize", leave);
      document.addEventListener("fullscreenchange", leave);
      document.addEventListener("webkitfullscreenchange", leave);
    };
  }, [id, position]);

  return (
    <div
      ref={tooltipRef}
      className={classNames(
        "hidden fixed border bg-black tracking-50 rounded-xxxl p-2 whitespace-no-wrap md:flex md:items-center md:justify-center",
        fullScreenVisible ? "border-fafafa" : "border-transparent"
      )}
      style={{ zIndex: 1, opacity: 0, visibility: "hidden" }}
    >
      <p className="text-12 text-white text-center px-2">{text}</p>
    </div>
  );
};

AppTooltip.propTypes = {
  id: Proptypes.string.isRequired,
  text: Proptypes.string.isRequired,
  position: Proptypes.string,
};
AppTooltip.defaultProps = {
  position: TOOLTIP_POSITION.TOP,
};
