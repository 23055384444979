import { useEffect, useMemo, useState } from "react";

import { PlaybackLoader } from "./PlaybackLoader";
import { PlaybackDispatcher } from "./PlaybackDispatcher";
import { ErrorView } from "./ErrorView";

import { useUserAgent } from "hooks/useUserAgent";

import { getPlaybackData } from "services/playback/data";

const ERROR_ID = "t_error";

export const AppWrapper = ({ locale }) => {
  const { isIpad, isTablet } = useUserAgent();

  // TODO: logic to validate session
  const [recordingIsReady, setRecordingIsReady] = useState(false);
  const [dataNotFoundError, setDataNotFoundError] = useState(false);
  const [captureData, setCaptureData] = useState({});

  const meetingId = useMemo(
    // TODO: WIP
    () => window.location.pathname.substring(1) || "TEST_MEETING",
    []
  );

  const preRenderedToken = useMemo(() => {
    let serverToken;

    if (process.env.NODE_ENV === "development") {
      const url = window.location.search;

      serverToken = url.split("t_token=")[1];
    } else {
      const element = document.getElementById("t_token");
      serverToken = element && element.innerHTML !== "::TOKEN::" ? element.innerHTML : undefined;
    }

    if (serverToken) {
      window.sessionStorage.setItem("token", serverToken);

      window.history.pushState({}, document.title, window.location.pathname);
      return serverToken;
    }

    const sessionStorageToken = window.sessionStorage.getItem("token");
    if (!serverToken && sessionStorageToken) return sessionStorageToken;

    return undefined;
  }, []);

  const preRenderedError = useMemo(() => {
    let error = null;

    if (process.env.NODE_ENV === "development") {
      const url = window.location.search;
      if (!url.includes(ERROR_ID)) return null;
      const [errorType, errorCode] = decodeURI(url.substring(1)).replace("t_error=", "").split("|");
      error = { errorType, errorCode: errorCode || null };
    } else {
      const element = document.getElementById(ERROR_ID);
      const text = element && element.innerHTML !== "::ERROR::" ? element.innerHTML : null;
      if (text) {
        const [errorType, errorCode] = text.split("|");
        error = { errorType, errorCode: errorCode || null };
      }
    }

    return error;
  }, []);

  const getData = async (token) => {
    if (!token) return;

    const data = await getPlaybackData(token);

    if (!data) {
      setDataNotFoundError(true);
      return;
    }

    setCaptureData(data);

    setRecordingIsReady(true);
  };

  useEffect(() => {
    if (!preRenderedToken) return;

    getData(preRenderedToken);
  }, [preRenderedToken]);

  if (isTablet && isIpad) {
    return <ErrorView type={ErrorView.types.IPAD_NOT_SUPPORTED} />;
  }

  if (!!preRenderedError || dataNotFoundError || !preRenderedToken) {
    if (!!preRenderedError) {
      const { errorType, errorCode } = preRenderedError;
      if (Object.keys(ErrorView.types).includes(errorType)) {
        return <ErrorView type={ErrorView.types[errorType]} code={errorCode} />;
      }
    }

    return <ErrorView type={ErrorView.types.ERROR_404} />;
  }

  return (
    <>
      {recordingIsReady ? (
        <PlaybackDispatcher meetingId={meetingId} data={captureData} />
      ) : (
        <PlaybackLoader />
      )}
    </>
  );
};
