import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { nanoid } from "@reduxjs/toolkit";
import get from "lodash.get";

import { AppIcon } from "../AppIcon";
import { AppTooltip, TOOLTIP_POSITION } from "../AppTooltip";
import { OpacityClickeable } from "../OpacityClickeable";

import { hasBrowserTouchScreen } from "lib/apis/agent";
import { ICON_TYPES, ICON_SIZES } from "lib/icons";

export const IconClickeable = ({
  size,
  active,
  disabled,
  icon,
  activeIcon,
  disabledIcon,
  label,
  tooltipPosition,
  activeLabel,
  disabledLabel,
  fill,
  activeFill,
  disabledFill,
  hoverFill,
  activeHoverFill,
  hideTooltip,
  disableOpacityHover,
  disableHover,
  dimensionsAuto,
  className,
  disabledRoundedCircle,
  style,
  onClick,
}) => {
  const id = useMemo(() => nanoid(10), []);
  const { width, height } = get(ICON_SIZES, size, ICON_SIZES.md);

  return (
    <>
      <OpacityClickeable
        data-tooltip-id={id}
        disabled={disabled}
        disableHover={disableOpacityHover}
        className={classNames(
          { [`fill-${disabledFill}`]: disabled },
          { [`fill-${activeFill}`]: active && !disabled },
          {
            [`fill-${fill}`]: !active && !disabled,
          },
          {
            [`hover:fill-${activeHoverFill}`]: active && !disabled && !disableHover,
          },
          {
            [`hover:fill-${hoverFill}`]: !active && !disabled && !disableHover,
          },
          "flex justify-center items-center",
          { "rounded-circle": !disabledRoundedCircle },
          className
        )}
        style={dimensionsAuto ? { ...style } : { width, height, ...style }}
        onClick={onClick}
      >
        <AppIcon
          type={disabled ? disabledIcon || icon : active ? activeIcon || icon : icon}
          size={24}
        />
      </OpacityClickeable>
      {hasBrowserTouchScreen || hideTooltip || (
        <AppTooltip
          id={id}
          text={disabled ? disabledLabel || label : active ? activeLabel || label : label}
          position={tooltipPosition}
        />
      )}
    </>
  );
};

IconClickeable.types = ICON_TYPES;
IconClickeable.tooltipPosition = TOOLTIP_POSITION;

IconClickeable.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  activeIcon: PropTypes.string,
  disabledIcon: PropTypes.string,
  label: PropTypes.string,
  tooltipPosition: PropTypes.string,
  activeLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
  fill: PropTypes.string,
  activeFill: PropTypes.string,
  disabledFill: PropTypes.string,
  hoverFill: PropTypes.string,
  activeHoverFill: PropTypes.string,
  hideTooltip: PropTypes.bool,
  disableHover: PropTypes.bool,
  disableOpacityHover: PropTypes.bool,
  dimensionsAuto: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  disabledRoundedCircle: PropTypes.bool,
  onClick: PropTypes.func,
};
IconClickeable.defaultProps = {
  active: false,
  disabled: false,
  activeIcon: "",
  disabledIcon: "",
  label: "",
  tooltipPosition: TOOLTIP_POSITION.TOP,
  activeLabel: "",
  disabledLabel: "",
  fill: "black50",
  activeFill: "turquoise",
  hoverFill: "black",
  disabledFill: "black25",
  activeHoverFill: "turquoise",
  disableHover: false,
  disableOpacityHover: false,
  dimensionsAuto: false,
  hideTooltip: false,
  size: ICON_SIZES.md.key,
  className: "",
  disabledRoundedCircle: false,
  onClick: () => null,
};
