import { configureStore } from "@reduxjs/toolkit";

import { filesReducer } from "./files";
import { notesReducer } from "./notes";
import { playerReducer } from "./player";
import { navigationReducer } from "./navigation";
import { slidesReducer } from "./slides";
import { drawingsReducer } from "./drawings";
import { usersReducer } from "./users";
import { messagesReducer } from "./mesages";
import { videosReducer } from "./videos";

export const store = configureStore({
  reducer: {
    files: filesReducer,
    notes: notesReducer,
    player: playerReducer,
    navigation: navigationReducer,
    slides: slidesReducer,
    drawings: drawingsReducer,
    users: usersReducer,
    messages: messagesReducer,
    videos: videosReducer,
  },
});
