import { createSlice } from "@reduxjs/toolkit";

export const videosSlice = createSlice({
  name: "videos",
  initialState: [],
  reducers: {
    received: (state, action) => action.payload,
  },
});

export const videosActions = videosSlice.actions;
export const videosReducer = videosSlice.reducer;
