import React from "react";
import classNames from "classnames";

import { LineDrawing } from "../LineDrawing";
import { DrawingWrapper } from "./DrawingWrapper";

import { DRAWING_TYPE, getPathByShape, SHAPE_GROUP_CONTROLS } from "lib/drawings";

export const WhiteboardDrawing = ({
  drawing,
  drawingOffset,
  zoomFactor,
  strokeWidthScaleFactor,
}) => {
  if (!drawing) return null;

  const widthDrawing = drawing.width * zoomFactor;
  const heightDrawing = drawing.height * zoomFactor;

  if (drawing.typeDrawing === DRAWING_TYPE.TEXT) {
    return (
      <DrawingWrapper
        dataDrawing={drawing}
        drawingOffset={drawingOffset}
        heightDrawing={heightDrawing}
        widthDrawing={widthDrawing}
        zoomFactor={zoomFactor}
      >
        <div className={classNames(`text-palette-${drawing.color}`, "w-full h-full")}>
          <textarea
            style={{ fontSize: drawing.size * (widthDrawing / 100) }}
            className="w-full h-full resize-none bg-transparent overflow-hidden"
            readOnly
            value={drawing.text}
          />
        </div>
      </DrawingWrapper>
    );
  }

  if (drawing.typeShape === SHAPE_GROUP_CONTROLS.SHAPE_LINE) {
    return (
      <LineDrawing
        dataDrawing={drawing}
        drawingOffset={drawingOffset}
        strokeWidthScaleFactor={strokeWidthScaleFactor}
        zoomFactor={zoomFactor}
      />
    );
  }

  if (drawing.typeDrawing === DRAWING_TYPE.PATH) {
    return (
      <DrawingWrapper
        dataDrawing={drawing}
        drawingOffset={drawingOffset}
        heightDrawing={heightDrawing}
        widthDrawing={widthDrawing}
        zoomFactor={zoomFactor}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={widthDrawing}
          height={heightDrawing}
          className="overflow-visible w-full h-full"
        >
          <path
            className={`stroke-palette-${drawing.color} fill-transparent`}
            style={{ pointerEvents: "auto" }}
            d={getPathByShape(
              drawing.typeShape,
              widthDrawing,
              heightDrawing,
              strokeWidthScaleFactor
            )}
            strokeWidth={strokeWidthScaleFactor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </DrawingWrapper>
    );
  }

  return null;
};
