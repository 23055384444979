import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as CastIcon } from "assets/icons/cast.svg";
import { ReactComponent as ChatOnIcon } from "assets/icons/chat-on.svg";
import { ReactComponent as ChatOffIcon } from "assets/icons/chat-off.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import { ReactComponent as ChevronUpIcon } from "assets/icons/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as DocBoxIcon } from "assets/icons/doc-box.svg";
import { ReactComponent as DocxBoxIcon } from "assets/icons/docx-box.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as DownloadMultipleIcon } from "assets/icons/download-multiple.svg";
import { ReactComponent as EmptyLibraryIcon } from "assets/icons/illustrations/empty-library.svg";
import { ReactComponent as EmptyNotesIcon } from "assets/icons/illustrations/empty-notes.svg";
import { ReactComponent as EmptySlidesIcon } from "assets/icons/illustrations/empty-slides.svg";
import { ReactComponent as ExitFullScreenIcon } from "assets/icons/exit-full-screen.svg";
import { ReactComponent as ExpandIcon } from "assets/icons/expand.svg";
import { ReactComponent as FastForwardIcon } from "assets/icons/fast-forward.svg";
import { ReactComponent as FastRewindIcon } from "assets/icons/fast-rewind.svg";
import { ReactComponent as FullScreen } from "assets/icons/full-screen.svg";
import { ReactComponent as JpegBoxIcon } from "assets/icons/jpeg-box.svg";
import { ReactComponent as JpgBoxIcon } from "assets/icons/jpg-box.svg";
import { ReactComponent as LibraryIcon } from "assets/icons/library.svg";
import { ReactComponent as MonitorIcon } from "assets/icons/monitor.svg";
import { ReactComponent as NotesIcon } from "assets/icons/notes.svg";
import { ReactComponent as PauseIcon } from "assets/icons/pause.svg";
import { ReactComponent as PdfBoxIcon } from "assets/icons/pdf-box.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";
import { ReactComponent as PngBoxIcon } from "assets/icons/png-box.svg";
import { ReactComponent as PresentationIcon } from "assets/icons/presentation.svg";
import { ReactComponent as PollIcon } from "assets/icons/poll.svg";
import { ReactComponent as PollOffIcon } from "assets/icons/poll-off.svg";
import { ReactComponent as PptBoxIcon } from "assets/icons/ppt-box.svg";
import { ReactComponent as PptxBoxIcon } from "assets/icons/pptx-box.svg";
import { ReactComponent as ScreenRotationIcon } from "assets/icons/screen-rotation.svg";
import { ReactComponent as SpeakerFaceIcon } from "assets/icons/speaker-face.svg";
import { ReactComponent as SubtitlesIcon } from "assets/icons/subtitles.svg";
import { ReactComponent as SubtitlesOffIcon } from "assets/icons/subtitles-off.svg";
import { ReactComponent as VideoLinkIcon } from "assets/icons/video-link.svg";
import { ReactComponent as VimeoIcon } from "assets/icons/vimeo.svg";
import { ReactComponent as VolumeHighIcon } from "assets/icons/volume-high.svg";
import { ReactComponent as VolumeLowIcon } from "assets/icons/volume-low.svg";
import { ReactComponent as VolumeMuteIcon } from "assets/icons/volume-mute.svg";
import { ReactComponent as YouTubeIcon } from "assets/icons/youtube.svg";

// For error views.
import { ReactComponent as Error404Icon } from "assets/icons/errors/404.svg";
import { ReactComponent as IpadNotSupportedIcon } from "assets/icons/errors/ipad-not-supported.svg";
import { ReactComponent as SessionDoesntExistIcon } from "assets/icons/errors/session-doesnt-exist.svg";

import { ICON_TYPES } from "lib/icons";

const InnerIcon = ({ type, ...props }) => {
  switch (type) {
    case ICON_TYPES.CAST:
      return <CastIcon {...props} />;
    case ICON_TYPES.CHAT_ON:
      return <ChatOnIcon {...props} />;
    case ICON_TYPES.CHAT_OFF:
      return <ChatOffIcon {...props} />;
    case ICON_TYPES.CHEVRON_LEFT:
      return <ChevronLeftIcon {...props} />;
    case ICON_TYPES.CHEVRON_RIGHT:
      return <ChevronRightIcon {...props} />;
    case ICON_TYPES.CHEVRON_UP:
      return <ChevronUpIcon {...props} />;
    case ICON_TYPES.CHEVRON_DOWN:
      return <ChevronDownIcon {...props} />;
    case ICON_TYPES.JPG_BOX:
      return <JpgBoxIcon {...props} />;
    case ICON_TYPES.JPEG_BOX:
      return <JpegBoxIcon {...props} />;
    case ICON_TYPES.PNG_BOX:
      return <PngBoxIcon {...props} />;
    case ICON_TYPES.DOC_BOX:
      return <DocBoxIcon {...props} />;
    case ICON_TYPES.DOCX_BOX:
      return <DocxBoxIcon {...props} />;
    case ICON_TYPES.DOWNLOAD:
      return <DownloadIcon {...props} />;
    case ICON_TYPES.DOWNLOAD_MULTIPLE:
      return <DownloadMultipleIcon {...props} />;
    case ICON_TYPES.REQUEST_TIMESTAMP_REQUIRED:
    case ICON_TYPES.REQUEST_TIMESTAMP_EXPIRED:
    case ICON_TYPES.ACCOUNT_ID_REQUIRED:
    case ICON_TYPES.ORGANIZATION_DOESNT_EXIST:
    case ICON_TYPES.CHECKSUM_IS_NOT_VALID:
    case ICON_TYPES.ERROR_404:
      return <Error404Icon style={{ width: "100%", maxWidth: 344 }} />;
    case ICON_TYPES.EMPTY_LIBRARY:
      return (
        <EmptyLibraryIcon
          style={{ width: "100%", maxWidth: 200, height: "100%", maxHeight: 250 }}
          {...props}
        />
      );
    case ICON_TYPES.EMPTY_NOTES:
      return (
        <EmptyNotesIcon
          style={{ width: "100%", maxWidth: 200, height: "100%", maxHeight: 250 }}
          {...props}
        />
      );
    case ICON_TYPES.EMPTY_SLIDES:
      return (
        <EmptySlidesIcon
          style={{ width: "100%", maxWidth: 200, height: "100%", maxHeight: 250 }}
          {...props}
        />
      );
    case ICON_TYPES.EXIT_FULL_SCREEN:
      return <ExitFullScreenIcon {...props} />;
    case ICON_TYPES.EXPAND:
      return <ExpandIcon {...props} />;
    case ICON_TYPES.FAST_FORWARD:
      return <FastForwardIcon {...props} />;
    case ICON_TYPES.FAST_REWIND:
      return <FastRewindIcon {...props} />;
    case ICON_TYPES.FULL_SCREEN:
      return <FullScreen {...props} />;
    case ICON_TYPES.IPAD_NOT_SUPPORTED:
      return <IpadNotSupportedIcon style={{ width: "100%", maxWidth: 220 }} />;
    case ICON_TYPES.LIBRARY:
      return <LibraryIcon {...props} />;
    case ICON_TYPES.MONITOR:
      return <MonitorIcon {...props} />;
    case ICON_TYPES.NOTES:
      return <NotesIcon {...props} />;
    case ICON_TYPES.PAUSE:
      return <PauseIcon {...props} />;
    case ICON_TYPES.PDF_BOX:
      return <PdfBoxIcon {...props} />;
    case ICON_TYPES.PLAY:
      return <PlayIcon {...props} />;
    case ICON_TYPES.PRESENTATION:
      return <PresentationIcon {...props} />;
    case ICON_TYPES.POLL:
      return <PollIcon {...props} />;
    case ICON_TYPES.POLL_OFF:
      return <PollOffIcon {...props} />;
    case ICON_TYPES.PPT_BOX:
      return <PptBoxIcon {...props} />;
    case ICON_TYPES.PPTX_BOX:
      return <PptxBoxIcon {...props} />;
    case ICON_TYPES.SCREEN_ROTATION:
      return <ScreenRotationIcon {...props} />;
    case ICON_TYPES.SESSION_DOESNT_EXIST:
      return <SessionDoesntExistIcon style={{ width: "100%", maxWidth: 344 }} />;
    case ICON_TYPES.SPEAKER_FACE:
      return <SpeakerFaceIcon {...props} />;
    case ICON_TYPES.SUBTITLES:
      return <SubtitlesIcon {...props} />;
    case ICON_TYPES.SUBTITLES_OFF:
      return <SubtitlesOffIcon {...props} />;
    case ICON_TYPES.VIDEO_LINK:
      return <VideoLinkIcon {...props} />;
    case ICON_TYPES.VIMEO:
      return <VimeoIcon {...props} />;
    case ICON_TYPES.VOLUME_HIGH:
      return <VolumeHighIcon {...props} />;
    case ICON_TYPES.VOLUME_LOW:
      return <VolumeLowIcon {...props} />;
    case ICON_TYPES.YOUTUBE:
      return <YouTubeIcon {...props} />;
    case ICON_TYPES.VOLUME_MUTE:
    default:
      return <VolumeMuteIcon {...props} />;
  }
};

export const AppIcon = ({ type, size, ...restProps }) => (
  <InnerIcon type={type} width={size} height={size} {...restProps} />
);

AppIcon.types = ICON_TYPES;

AppIcon.propTypes = {
  size: PropTypes.number,
  type: PropTypes.oneOf(Object.values(ICON_TYPES)),
};

AppIcon.defaultProps = {
  size: 16,
};
