import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const OpacityClickeable = ({
  className,
  disabled,
  disableHover,
  children,
  onClick,
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        className,
        {
          "cursor-pointer": !disabled,
          "hover:bg-black hover:bg-opacity-5": !disabled && !disableHover,
        },
        "select-none"
      )}
      onClick={disabled ? () => null : onClick}
      {...restProps}
    >
      {children}
    </div>
  );
};
OpacityClickeable.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableHover: PropTypes.bool,
  onClick: PropTypes.func,
};
OpacityClickeable.defaultProps = {
  className: "",
  disabled: false,
  disableHover: false,
  onClick: () => null,
};
