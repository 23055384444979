import { createSlice } from "@reduxjs/toolkit";

export const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
  },
  reducers: {
    messagesUpdated: (state, action) => {
      state.messages = action.payload;
    },
    addNewMessages: (state, action) => {
      state.messages = [...state.messages, ...action.payload];
    },
  },
});

export const messagesActions = messagesSlice.actions;
export const messagesReducer = messagesSlice.reducer;
