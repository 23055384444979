export const PEN_GROUP_CONTROLS = {
  PEN_LIGHT: "PEN_LIGHT",
  PEN_MEDIUM: "PEN_MEDIUM",
  PEN_BOLD: "PEN_BOLD",
};

export const SHAPE_GROUP_CONTROLS = {
  SHAPE_LINE: "SHAPE_LINE",
  SHAPE_TRIANGLE: "SHAPE_TRIANGLE",
  SHAPE_SQUARE: "SHAPE_SQUARE",
  SHAPE_CIRCLE: "SHAPE_CIRCLE",
  SHAPE_ARROW_LEFT: "SHAPE_ARROW_LEFT",
  SHAPE_ARROW_RIGHT: "SHAPE_ARROW_RIGHT",
  SHAPE_COMMENT: "SHAPE_COMMENT",
  SHAPE_HALF_CIRCLE: "SHAPE_HALF_CIRCLE",
};

export const TEXT_GROUP_CONTROLS = {
  TEXT_SMALL: "TEXT_SMALL",
  TEXT_MEDIUM: "TEXT_MEDIUM",
  TEXT_LARGE: "TEXT_LARGE",
};

export const COLOR_CONTROLS = {
  BLACK: "black",
  GREEN: "green",
  YELLOW: "yellow",
  RED: "red",
};

export const getLineDrawing = (c1, c2) => ({
  path: `M${c1.x} ${c1.y} L${c2.x} ${c2.y}`,
});

export const getPenDrawingWidth = (control) => {
  switch (control) {
    case PEN_GROUP_CONTROLS.PEN_BOLD:
      return 8;
    case PEN_GROUP_CONTROLS.PEN_LIGHT:
      return 2;
    case PEN_GROUP_CONTROLS.PEN_MEDIUM:
    default:
      return 4;
  }
};

export const getTextDrawingSize = (control) => {
  switch (control) {
    case TEXT_GROUP_CONTROLS.TEXT_LARGE:
      return 24;
    case TEXT_GROUP_CONTROLS.TEXT_SMALL:
      return 12;
    case TEXT_GROUP_CONTROLS.TEXT_MEDIUM:
    default:
      return 16;
  }
};

export const DRAWING_TYPE = {
  PATH: "PATH",
  PEN: "PEN",
  TEXT: "TEXT",
};

export const DRAWING_UNDOREDO = {
  UNDO: "UNDO",
  REDO: "REDO",
};

export const DRAWING_UNDOREDO_STATUS = {
  STATIC: "STATIC",
  MOVING: "MOVING",
  RESIZING: "RESIZING",
  CREATING: "CREATING",
};

export const DRAWING_UNDOREDO_ACTION = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  MOVE_OR_RESIZE: "MOVE_OR_RESIZE",
  DRAW: "DRAW",
  WRITE: "WRITE",
};

export const DRAWING_CONTROL = {
  RESIZE_MIDDLE_TOP: "RESIZE_MIDDLE_TOP",
  RESIZE_MIDDLE_LEFT: "RESIZE_MIDDLE_LEFT",
  RESIZE_MIDDLE_RIGHT: "RESIZE_MIDDLE_RIGHT",
  RESIZE_MIDDLE_BOTTOM: "RESIZE_MIDDLE_BOTTOM",
  RESIZE_CORNER_BOTTOM_LEFT: "RESIZE_CORNER_BOTTOM_LEFT",
  RESIZE_CORNER_BOTTOM_RIGHT: "RESIZE_CORNER_BOTTOM_RIGHT",
  RESIZE_CORNER_TOP_LEFT: "RESIZE_CORNER_TOP_LEFT",
  RESIZE_CORNER_TOP_RIGHT: "RESIZE_CORNER_TOP_RIGHT",
  ROTATION: "ROTATION",
  FIRST_CIRCLE_AT_LINE: "FIRST_CIRCLE_AT_LINE",
  SECOND_CIRCLE_AT_LINE: "SECOND_CIRCLE_AT_LINE",
};

export const DRAWING_STATUS = {
  DRAWING: "DRAWING",
  STATELESS: "STATELESS",
  MOVEMENTING: "MOVEMENTING",
  RESIZING: "RESIZING",
  ROTATION: "ROTATION",
};

// To know the location of the drawing in the cartesian plane.
export const DRAWING_POINTING_TO = {
  TOP_RIGHT: "TOP_RIGHT",
  TOP_LEFT: "TOP_LEFT",
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
  BOTTOM_LEFT: "BOTTOM_LEFT",
};

export const getPathByShape = (typeShape, width, height, strokeWidth = null) => {
  switch (typeShape) {
    case SHAPE_GROUP_CONTROLS.SHAPE_SQUARE:
      return getSquareDrawing({ x: width, y: height }, strokeWidth);
    case SHAPE_GROUP_CONTROLS.SHAPE_ARROW_RIGHT:
      return getRightArrowDrawing({ x: width, y: height }, strokeWidth);
    case SHAPE_GROUP_CONTROLS.SHAPE_ARROW_LEFT:
      return getLeftArrowDrawing({ x: width, y: height }, strokeWidth);
    case SHAPE_GROUP_CONTROLS.SHAPE_TRIANGLE:
      return getTriangleDrawing({ x: width, y: height }, strokeWidth);
    case SHAPE_GROUP_CONTROLS.SHAPE_COMMENT:
      return getBubbleCommentDrawing({ x: width, y: height }, strokeWidth);
    case SHAPE_GROUP_CONTROLS.SHAPE_CIRCLE:
      return getCircleDrawing({ x: width, y: height }, strokeWidth);
    case SHAPE_GROUP_CONTROLS.SHAPE_HALF_CIRCLE:
      return getHalfCircleDrawing({ x: width, y: height }, strokeWidth);
    default:
      break;
  }
};

/** @param {String} str */
export const getSvgPathFromStroke = (str) => {
  if (!str.length) return "";

  const d = str.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ["M", ...str[0], "Q"]
  );

  d.push("Z");
  return d.join(" ");
};

export const getSquareDrawing = (c2, strokeWidth) => {
  let strokeWidthHalf = strokeWidth / 2;
  return `M${strokeWidthHalf} ${strokeWidthHalf} L${c2.x - strokeWidthHalf} ${strokeWidthHalf} L${
    c2.x - strokeWidthHalf
  } ${c2.y - strokeWidthHalf} L${strokeWidthHalf} ${
    c2.y - strokeWidthHalf
  } L${strokeWidthHalf} ${strokeWidthHalf}`;
};

export const getCircleDrawing = (c2, strokeWidth) => {
  let strokeWidthHalf = strokeWidth / 2;
  return `
    M ${strokeWidthHalf}

      ${c2.y / 2} a ${c2.x / 2 - strokeWidthHalf}
      ${c2.y / 2 - strokeWidth}
      0 1 0
      ${c2.x - strokeWidth}

      0 a ${c2.x / 2 - strokeWidthHalf}
      ${c2.y / 2 - strokeWidth}
      0 1 0
      -${c2.x - strokeWidth} 0`;
};

export const getRightArrowDrawing = (c2, strokeWidth) => {
  let strokeWidthHalf = strokeWidth / 2;
  let axisXright = c2.x;
  let axisXleft = c2.x;
  let axisXrightHalf = c2.x - c2.x / 2;
  let axisYtop = c2.y - c2.y;
  let axisYbottom = c2.y;
  let axisXfoldRight = c2.y / 3;
  let axisXfoldLeft = (c2.y / 3) * 2;
  let axisYhalf = c2.y / 2;

  if (c2.x > strokeWidthHalf) {
    axisXleft = strokeWidthHalf;
  } else {
    axisXright = strokeWidthHalf;
  }

  if (c2.y < strokeWidthHalf) {
    axisYtop = strokeWidthHalf - (strokeWidthHalf - c2.y);
    axisYbottom = strokeWidthHalf;
    axisXfoldRight = c2.y + (strokeWidthHalf - c2.y) / 3;
    axisXfoldLeft = c2.y + ((strokeWidthHalf - c2.y) / 3) * 2;
  }

  return `
      M ${axisXrightHalf} ${axisYbottom}
      L ${axisXright} ${axisYhalf}
      L ${axisXrightHalf} ${axisYtop}
      L ${axisXrightHalf} ${axisXfoldRight}
      L ${axisXleft} ${axisXfoldRight}
      L ${axisXleft} ${axisXfoldLeft}
      L ${axisXrightHalf} ${axisXfoldLeft}
      L ${axisXrightHalf} ${axisYbottom}
    `;
};

export const getLeftArrowDrawing = (c2, strokeWidth = null) => {
  let strokeWidthHalf = strokeWidth / 2;
  let axisXleft = c2.x;
  let axisXright = c2.x;
  let axisXrightHalf = c2.x - c2.x / 2;
  let axisYtop = c2.y - c2.y;
  let axisYbottom = c2.y;
  let axisXfoldRight = c2.y / 3;
  let axisXfoldLeft = (c2.y / 3) * 2;
  let axisYhalf = c2.y / 2;

  if (c2.x > strokeWidthHalf) {
    axisXright = strokeWidthHalf;
  } else {
    axisXleft = strokeWidthHalf;
  }

  if (c2.y < strokeWidthHalf) {
    axisYtop = strokeWidthHalf - (strokeWidthHalf - c2.y);
    axisYbottom = strokeWidthHalf;
    axisXfoldRight = c2.y + (strokeWidthHalf - c2.y) / 3;
    axisXfoldLeft = c2.y + ((strokeWidthHalf - c2.y) / 3) * 2;
  }

  return `
      M ${axisXrightHalf} ${axisYbottom}
      L ${axisXright} ${axisYhalf}
      L ${axisXrightHalf} ${axisYtop}
      L ${axisXrightHalf} ${axisXfoldRight}
      L ${axisXleft - strokeWidthHalf} ${axisXfoldRight}
      L ${axisXleft - strokeWidthHalf} ${axisXfoldLeft}
      L ${axisXrightHalf} ${axisXfoldLeft}
      L ${axisXrightHalf} ${axisYbottom}
    `;
};

export const getHalfCircleDrawing = (c2, strokeWidth) => {
  let strokeWidthHalf = strokeWidth / 2;
  let halfCircleWidth = c2.x;
  let halfCircleHeight = c2.y;
  let ratio = (halfCircleWidth - strokeWidthHalf) / (halfCircleHeight - strokeWidthHalf);
  let optimumCircleHeight = 2 / ratio;

  return `
    M ${strokeWidthHalf} ${c2.y - strokeWidthHalf}
    L ${strokeWidthHalf} ${c2.y - strokeWidthHalf}
    A 1 ${optimumCircleHeight} 0 0 1 ${c2.x - strokeWidthHalf} ${c2.y - strokeWidthHalf}
    L ${c2.x - strokeWidthHalf} ${c2.y - strokeWidthHalf}
    L ${strokeWidthHalf} ${c2.y - strokeWidthHalf}
    `;
};

export const getBubbleCommentDrawing = (c2, strokeWidth) => {
  let strokeWidthHalf = strokeWidth / 2;
  let axisXright = c2.x;
  let axisYtop = c2.y;
  let axisXhalf = c2.x - c2.x / 2;
  let axisYfoldRight = (c2.y / 3) * 2;
  let axisXfoldRight = (c2.x / 3) * 2;
  let axisXfoldLeft = (c2.x / 3) * 1;
  let axisX = c2.x;
  let axisY = c2.y;

  if (c2.x < strokeWidthHalf) {
    axisXright = strokeWidthHalf;
    axisXfoldLeft = c2.x + ((strokeWidthHalf - c2.x) / 3) * 1;
    axisXfoldRight = c2.x + ((strokeWidthHalf - c2.x) / 3) * 2;
  } else {
    axisX = strokeWidthHalf;
  }

  if (c2.y < strokeWidthHalf) {
    axisYtop = strokeWidthHalf;
    axisYfoldRight = c2.y + ((strokeWidthHalf - c2.y) / 3) * 2;
  } else {
    axisY = strokeWidthHalf;
  }

  return `
      M ${axisX} ${axisYfoldRight}
      L ${axisX} ${axisY}
      L ${axisXright - strokeWidthHalf} ${axisY}
      L ${axisXright - strokeWidthHalf} ${axisYfoldRight}
      L ${axisXfoldRight} ${axisYfoldRight}
      L ${axisXhalf} ${axisYtop}
      L ${axisXfoldLeft} ${axisYfoldRight}
      L ${axisX} ${axisYfoldRight}
    `;
};

export const getTriangleDrawing = (c2, strokeWidth) => {
  let strokeWidthHalf = strokeWidth / 2;
  return `M${strokeWidthHalf} ${c2.y - strokeWidthHalf} L${c2.x / 2} ${strokeWidthHalf} L${c2.x} ${
    c2.y - strokeWidthHalf
  } ${strokeWidthHalf} ${c2.y - strokeWidthHalf}`;
};
