module.exports = {
  important: true,
  separator: ":",
  purge: {
    content: ["./src/**/*.jsx", "./src/**/*.js"],
    options: { whitelistPatterns: [/^bg-/, /^fill-/, /^stroke-/, /^text-/] },
  },
  theme: {
    backgroundColor: (theme) => theme("colors"),
    backgroundOpacity: (theme) => theme("opacity"),
    borderColor: (theme) => theme("colors"),
    borderRadius: {
      none: "0",
      xs: "1px",
      sm: "2px",
      default: "3px",
      md: "4px",
      lg: "5px",
      xl: "6px",
      xxl: "8px",
      xxxl: "10px",
      xxxxl: "16px",
      xxxxxl: "20px",
      xxxxxxl: "24px",
      circle: "50%",
    },
    borderWidth: {
      default: "1px",
      semi: "0.5px",
      0: "0",
      2: "2px",
      3: "3px",
      4: "4px",
      5: "5px",
      6: "6px",
      8: "8px",
    },
    boxShadow: {
      none: "none",
      top: "0px -2px 4px -1px #33374540",
      bottom: "0px 2px 4px -1px #33374540",
      "bottom-lg": "0px 3px 5px -1px #33374540",
      right: "2px 0px 4px -1px #33374540",
      "right-lg": "4px 0px 8px -3px #33374540",
      all: "0px 0px 8px #3337451F",
    },
    colors: {
      transparent: "transparent",
      white: "#FFFFFF",
      white50: "#FFFFFF80",
      white75: "#FFFFFFBF",
      red: "#F05D5E",
      yellow: "#FFBF00",
      gray: "#F3F2F2",
      black12: "#23293D1F",
      black25: "#CCCDD0",
      black50: "#23293D80",
      black75: "#666974",
      black: "#23293D",
      turquoise: "#54D1C9",
      bluedark: "#001e47",
      fafafa: "#FAFAFA",
      fafafa12: "#FAFAFA1F",
      dark: "#111A21",
      palette: {
        primary1: "#000000",
        primary2: "#2196F3",
        primary3: "#4CAF50",
        primary4: "#FFEB3B",
        primary5: "#EF2B2D",
        color1: "#000000",
        color2: "#7001B7",
        color3: "#002B7F",
        color4: "#007A3D",
        color5: "#83B201",
        color6: "#FFC61E",
        color7: "#C13828",
        color8: "#772D35",
        color9: "#444F51",
        color10: "#B023A5",
        color11: "#0072C5",
        color12: "#00B760",
        color13: "#BAD809",
        color14: "#F9E713",
        color15: "#F96301",
        color16: "#EF2B2D",
        color17: "#AEAEAE",
        color18: "#FF64F0",
        color19: "#16D6FF",
        color20: "#00D8AC",
        color21: "#B0FF14",
        color22: "#FFFFFF",
        color23: "#FFBC72",
        color24: "#FF5D85",
      },
    },
    fill: (theme) => theme("colors"),
    fontSize: {
      10: "10px",
      12: "12px",
      14: "14px",
      16: "16px",
      20: "20px",
      24: "24px",
      34: "34px",
      48: "48px",
      60: "60px",
      96: "96px",
    },
    fontFamily: {
      nunito: ["Nunito", "sans-serif"],
      "red-hat": ["Red Hat Display", "sans-serif"],
    },
    gap: {
      0: "0",
      1: "2px",
      2: "4px",
      3: "6px",
      4: "8px",
      5: "10px",
      6: "12px",
      7: "14px",
      8: "16px",
      9: "18px",
      10: "20px",
      11: "22px",
      12: "24px",
      13: "26px",
      14: "28px",
      15: "30px",
      16: "32px",
    },
    height: {
      none: "none",
      auto: "auto",
      full: "100%",
      screen: "100vh",
      12: "24px",
      23: "46px",
    },
    margin: {
      auto: "auto",
      "-5": "-10px",
      "-4": "-8px",
      "-3": "-6px",
      "-2": "-4px",
      "-1": "-2px",
      0: "0",
      1: "2px",
      2: "4px",
      3: "6px",
      4: "8px",
      5: "10px",
      6: "12px",
      7: "14px",
      8: "16px",
      9: "18px",
      10: "20px",
      12: "24px",
      16: "32px",
    },
    letterSpacing: {
      0: "0",
      15: "0.15px",
      25: ".25px",
      40: ".4px",
      44: ".44px",
      50: ".5px",
      100: "1px",
      135: "1.35px",
      150: "1.5px",
    },
    opacity: {
      inherit: "inherit",
      0: "0",
      5: ".05",
      15: ".15",
      25: ".25",
      35: ".35",
      50: ".5",
      75: ".75",
      80: ".80",
      90: ".90",
      100: "1",
    },
    padding: {
      0: "0",
      1: "2px",
      2: "4px",
      3: "6px",
      4: "8px",
      5: "10px",
      6: "12px",
      7: "14px",
      8: "16px",
      9: "18px",
      10: "20px",
      12: "24px",
    },
    screens: {
      xs: "481px",
      sm: "641px",
      md: "769px",
      lg: "1025px",
      xl: "1281px",
    },
    stroke: (theme) => theme("colors"),
    textColor: (theme) => theme("colors"),
    width: {
      none: "none",
      auto: "auto",
      full: "100%",
      screen: "100vw",
    },
    zIndex: {
      0: 0,
      10: 10,
      15: 15,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      100: 100,
    },
  },
  variants: {
    alignItems: ["responsive"],
    animation: [],
    backgroundColor: ["responsive", "hover", "disabled"],
    backgroundOpacity: ["responsive", "hover"],
    borderColor: ["hover"],
    borderRadius: ["hover", "responsive"],
    boxSizing: [],
    boxShadow: ["responsive"],
    borderStyle: [],
    borderWidth: ["responsive", "hover"],
    cursor: ["responsive"],
    display: ["responsive"],
    fill: ["hover", "group-hover"],
    flexDirection: ["responsive"],
    flexGrow: [],
    flexShrink: [],
    flexWrap: [],
    fontSize: [],
    fontStyle: [],
    fontWeight: [],
    height: ["responsive"],
    inset: ["responsive"],
    letterSpacing: [],
    lineHeight: [],
    justifyContent: ["responsive"],
    justifyItems: [],
    justiySelf: [],
    gap: ["responsive"],
    gridAutoFlow: [],
    gridColumn: [],
    gridColumnEnd: [],
    gridColumnStart: [],
    gridRow: [],
    gridRowEnd: [],
    gridRowStart: [],
    gridTemplateColumns: [],
    gridTemplateRows: [],
    margin: ["responsive"],
    objectFit: [],
    objectPosition: [],
    opacity: ["disabled", "group-hover"],
    outline: [],
    overflow: [],
    padding: ["responsive"],
    position: ["responsive"],
    resize: [],
    stroke: [],
    textAlign: [],
    textColor: ["hover", "disabled", "group-hover"],
    textOpacity: ["group-hover"],
    transitionDuration: [],
    transitionDelay: [],
    userSelect: [],
    visibility: [],
    width: [],
    wordBreak: [],
    whiteSpace: [],
    zIndex: ["responsive"],
  },
  corePlugins: {
    accessibility: false,
    alignContent: false,
    alignSelf: false,
    animation: false,
    appearance: false,
    backgroundAttachment: false,
    backgroundClip: false,
    backgroundImage: false,
    backgroundPosition: false,
    backgroundRepeat: false,
    backgroundSize: false,
    borderCollapse: false,
    borderOpacity: false,
    container: false,
    clear: false,
    divideColor: false,
    divideOpacity: false,
    divideStyle: false,
    divideWidth: false,
    float: false,
    fontSmoothing: false,
    fontVariantNumeric: false,
    gradientColorStops: false,
    listStylePosition: false,
    listStyleType: false,
    maxWidth: false,
    minHeight: false,
    minWidth: false,
    order: false,
    overscrollBehavior: false,
    placeContent: false,
    placeholderColor: false,
    placeholderOpacity: false,
    placeItems: false,
    placeSelf: false,
    pointerEvents: false,
    rotate: false,
    scale: false,
    skew: false,
    space: false,
    strokeWidth: false,
    tableLayout: false,
    textDecoration: false,
    textTransform: false,
    transform: false,
    transformOrigin: false,
    transitionProperty: false,
    transitionTimingFunction: false,
    translate: false,
    verticalAlign: false,
  },
  plugins: [],
};
