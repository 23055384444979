import { useState, useEffect } from "react";
import { fromEvent, interval } from "rxjs";
import { debounce, map } from "rxjs/operators";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const resize$ = fromEvent(window, "resize").pipe(
      map((ev) => {
        return {
          width: ev.target.innerWidth,
          height: ev.target.innerHeight,
        };
      })
    );

    const subscription = resize$.subscribe(setWindowSize);

    return () => subscription.unsubscribe();
  }, []);

  return windowSize;
};

export default useWindowSize;
