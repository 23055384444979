export const getItem = (key, fallbackValue) => {
  const item = window.localStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : fallbackValue;
  } catch (error) {
    return item || fallbackValue;
  }
};

export const setItem = (key, value) => {
  try {
    window.localStorage.setItem(
      key,
      typeof value === "string" ? value : JSON.stringify(value)
    );
  } catch (error) {
    console.error("There was an error trying to set an storage item", error);
  }
};
