import React, { useState, useEffect, useMemo, useCallback } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { AppClickeable } from "components/elements/AppClickeable";
import { AppIcon } from "components/elements/AppIcon";
import { IconClickeable } from "components/elements/IconClickeable";
import { ScreenSharingPresentation } from "./ScreenSharingPresentation";
import { WhiteboardPresentation } from "./WhiteboardPresentation";
import { VideoSharingPresentation } from "./VideoSharingPresentation";
import { SlidesPresentation } from "./SlidesPresentation";
import { ChatMessages } from "./ChatMessages";

import { eventTimer } from "services/timer";

import { navigationActions } from "store/navigation";
import { slidesActions } from "store/slides";

import { PRESENTATION_KEYS } from "lib/navigation";
import { handleToggleFulScreen } from "lib/apis/fullscreen";
import { hasBrowserFullscreen } from "lib/apis/agent";

import useMediaWidthFitted from "hooks/useMediaWidthFitted";

import {
  PRESENTATION_BUTTON_SIZE,
  MINI_PRESENTATION_HEIGHT,
  MINI_PRESENTATION_WIDTH,
  BREAKPOINTS,
} from "theme/ui";

export const Presentation = () => {
  const dispatch = useDispatch();

  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);
  const screenSharingActive = useSelector((state) => state.navigation.screenSharingActive);
  const videoSharingActive = useSelector((state) => state.navigation.videoSharingActive);
  const isMiniPresentationExpanded = useSelector(
    (state) => state.navigation.isMiniPresentationExpanded
  );
  const presentationControlsVisible = useSelector(
    (state) => state.navigation.presentationControlsVisible
  );
  const thumbnails = useSelector((state) => state.slides.thumbnails);
  const slideIndex = useSelector((state) => state.slides.slideIndex);
  const slidesVisible = useSelector((state) => state.slides.slidesVisible);

  const { isMediaReponsive, isMediaDesktop } = useMediaWidthFitted();

  const activePresentation = useMemo(() => {
    if (screenSharingActive) return PRESENTATION_KEYS.SCREEN_SHARING;
    if (videoSharingActive) return PRESENTATION_KEYS.VIDEO_SHARING;

    return PRESENTATION_KEYS.WHITEBOARD;
  }, [screenSharingActive, videoSharingActive]);

  const getPresentationBesidesScreenSharing = useCallback(() => {
    switch (activePresentation) {
      case PRESENTATION_KEYS.WHITEBOARD:
        return <WhiteboardPresentation />;
      case PRESENTATION_KEYS.VIDEO_SHARING:
        return <VideoSharingPresentation />;
      default:
        return null;
    }
  }, [activePresentation]);

  const handleChangePreviousSlide = (e) => {
    const currentIndex = slideIndex === null || slideIndex === 0 ? undefined : slideIndex - 1;

    if (currentIndex === undefined) {
      e.stopPropagation();
      return false;
    }

    const slideInteractionTime = thumbnails[currentIndex].time;
    eventTimer.requestSeek(slideInteractionTime);

    dispatch(slidesActions.slideIndexChanged(currentIndex));
    dispatch(slidesActions.slidesVisibleClosed());
  };

  const handleChangeNextSlide = (e) => {
    const lastIndex = thumbnails.length - 1;
    const currentIndex = slideIndex === lastIndex ? undefined : slideIndex + 1;

    if (currentIndex === undefined) {
      e.stopPropagation();
      return false;
    }

    const slideInteractionTime = thumbnails[currentIndex].time;
    eventTimer.requestSeek(slideInteractionTime);

    dispatch(slidesActions.slideIndexChanged(currentIndex));
    dispatch(slidesActions.slidesVisibleClosed());
  };

  return (
    <PresentationWrapper
      className={classNames(
        "shadow-all overflow-hidden rounded-none",
        { relative: !fullScreenVisible },
        {
          "md:absolute md:top-0 md:right-0 md:rounded-xxl md:mt-12 md:mr-12":
            fullScreenVisible && isMiniPresentationExpanded,
        },
        { "md:rounded-none": fullScreenVisible },
        {
          "md:rounded-xxl": !fullScreenVisible || (fullScreenVisible && isMiniPresentationExpanded),
        }
      )}
      fullScreenVisible={fullScreenVisible}
      isMiniPresentationExpanded={isMiniPresentationExpanded}
      onClick={() => {
        if (isMediaReponsive) {
          if (slidesVisible) return false;

          dispatch(navigationActions.presentationControlsToggled());
        }
      }}
    >
      {getPresentationBesidesScreenSharing()}
      {/* Screen Sharing should be always rendered to have acces to the video node from the start. */}
      <ScreenSharingPresentation />

      {isMiniPresentationExpanded && isMediaDesktop && (
        <AppClickeable
          className={classNames(
            "absolute top-0 left-0 flex items-center justify-center bg-black rounded-xxl",
            { "ml-6 mt-6": !fullScreenVisible },
            { "ml-4 mt-4": fullScreenVisible && isMiniPresentationExpanded }
          )}
          style={{ width: PRESENTATION_BUTTON_SIZE, height: PRESENTATION_BUTTON_SIZE }}
          onClick={() => dispatch(navigationActions.presentationToggled())}
        >
          <AppIcon type={AppIcon.types.EXPAND} className="fill-white" size={24} />
        </AppClickeable>
      )}

      <div className="flex md:hidden items-center absolute left-0 top-0 mt-4 ml-4 xs:mt-8 xs:ml-8">
        <PresentationControl
          active={presentationControlsVisible}
          className={classNames(
            "rounded-xxl",
            { "bg-black": !isMiniPresentationExpanded },
            { "bg-turquoise": isMiniPresentationExpanded }
          )}
        >
          <IconClickeable
            hideTooltip
            disableHover
            dimensionsAuto
            disabledRoundedCircle
            icon={IconClickeable.types.SPEAKER_FACE}
            fill="white"
            className="w-full h-full"
            onClick={() => dispatch(navigationActions.presentationToggled())}
          />
        </PresentationControl>
      </div>

      {fullScreenVisible && (
        <div
          className="flex md:hidden items-center absolute top-0 mt-4 xs:mt-8"
          style={{ left: "50%", transform: "translateX(-50%)" }}
          onClick={(e) => {
            if (slidesVisible) {
              dispatch(navigationActions.presentationControlsToggled());
              return false;
            }

            e.stopPropagation();
          }}
        >
          <PresentationControl
            active={presentationControlsVisible}
            className={classNames(
              "w-auto flex items-center gap-12 rounded-xxl px-4",
              { "bg-black": !slidesVisible },
              { "bg-turquoise": slidesVisible }
            )}
          >
            <IconClickeable
              hideTooltip
              disableHover
              dimensionsAuto
              disabledRoundedCircle
              icon={IconClickeable.types.CHEVRON_LEFT}
              fill={slideIndex === null || slideIndex === 0 ? "black75" : "white"}
              className="w-full h-full"
              onClick={handleChangePreviousSlide}
            />
            <IconClickeable
              hideTooltip
              disableHover
              dimensionsAuto
              disabledRoundedCircle
              icon={IconClickeable.types.PRESENTATION}
              fill="white"
              className="w-full h-full"
              onClick={() => dispatch(slidesActions.slidesVisibleToggled())}
            />
            <IconClickeable
              hideTooltip
              disableHover
              dimensionsAuto
              disabledRoundedCircle
              icon={IconClickeable.types.CHEVRON_RIGHT}
              fill={slideIndex === thumbnails.length - 1 ? "black75" : "white"}
              className="w-full h-full"
              onClick={handleChangeNextSlide}
            />
          </PresentationControl>
        </div>
      )}

      <SlidesPresentation />

      {hasBrowserFullscreen && !fullScreenVisible && (
        <div className="flex md:hidden items-center absolute right-0 bottom-0 mb-4 mr-4 xs:mb-8 xs:mr-8">
          <PresentationControl
            active={presentationControlsVisible}
            className="bg-black rounded-xxl"
          >
            <IconClickeable
              hideTooltip
              disableHover
              dimensionsAuto
              disabledRoundedCircle
              icon={IconClickeable.types.FULL_SCREEN}
              fill="white"
              className="w-full h-full"
              onClick={() => {
                if (fullScreenVisible) {
                  dispatch(slidesActions.slidesVisibleClosed());
                }

                handleToggleFulScreen(fullScreenVisible);
              }}
            />
          </PresentationControl>
        </div>
      )}
      <ChatMessages />
    </PresentationWrapper>
  );
};

export const PresentationWrapper = styled.div`
  grid-area: presentation;

  width: ${(props) =>
    props.fullScreenVisible && props.isMiniPresentationExpanded
      ? `${MINI_PRESENTATION_WIDTH}px`
      : "100%"};

  height: ${(props) =>
    props.fullScreenVisible && props.isMiniPresentationExpanded
      ? `${MINI_PRESENTATION_HEIGHT}px`
      : "100%"};

  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(props) =>
      props.fullScreenVisible &&
      props.isMiniPresentationExpanded &&
      css`
        position: relative !important;
        width: 100%;
        height: 100%;
      `};
  }
`;

const PresentationControl = styled.div`
  width: ${PRESENTATION_BUTTON_SIZE}px;
  height: ${PRESENTATION_BUTTON_SIZE}px;

  opacity: 1;
  visibility: visible;

  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(props) =>
      props.active
        ? css`
            opacity: 1;
            visibility: visible;
          `
        : css`
            opacity: 0;
            visibility: hidden;
          `}

    transition: opacity 0.3s, visibility 0.3s;
  }
`;
