import styled, { css } from "styled-components";
import {
  BREAKPOINTS,
  GRID_GAP,
  MINI_PRESENTATION_HEIGHT,
  MINI_PRESENTATION_WIDTH,
  PLAYBACK_CONTROLS_LG_HEIGHT,
  WINDOW_MARGIN,
} from "theme/ui";

const templateGridColumnsDesktop = css`
  ${(props) => `${props.presentationAreaWidth}px ${MINI_PRESENTATION_WIDTH}px`}
`;

const templateGridRowsDesktop = css`
  ${(props) =>
    `${MINI_PRESENTATION_HEIGHT}px ${
      props.presentationAreaHeight - MINI_PRESENTATION_HEIGHT - GRID_GAP
    }px ${PLAYBACK_CONTROLS_LG_HEIGHT}px`}
`;

const templateGridAreaDesktop = (isMiniPresentationExpanded) => {
  return isMiniPresentationExpanded
    ? ` "camera presentation"
        "camera panels"
        "controls panels"`
    : ` "presentation camera"
        "presentation panels"
        "controls panels"`;
};

export const PlaybackGrid = styled.div`
  display: grid;
  grid-template-columns: ${templateGridColumnsDesktop};
  grid-template-rows: ${templateGridRowsDesktop};
  grid-template-areas: ${(props) => templateGridAreaDesktop(props.isMiniPresentationExpanded)};
  grid-gap: ${GRID_GAP}px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: 1fr;
    grid-template-rows: ${(props) => props.presentationAreaHeight}px 1fr;
    grid-template-areas:
      "presentation"
      "panels";
    grid-gap: 0;
  }
`;

export const PlaybackContainer = styled.div`
  padding: ${(props) => (props.fullScreenVisible ? 0 : WINDOW_MARGIN)}px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    padding: 0;
  }
`;
