import { useMemo } from "react";

import useWindowSize from "./useWindowSize";
import {
  anotherBrowser,
  isAndroid,
  isChrome,
  isDesktop,
  isEdge,
  isFirefox,
  isIOS,
  isIpad,
  isIphone,
  isMobile,
  isSafari,
  isTablet,
} from "lib/apis/agent";

import { screens } from "theme";

export const useUserAgent = () => {
  const windowSize = useWindowSize();

  return useMemo(() => {
    const { width: windowWidth, isPortrait, isLandscape, height: windowHeight } = windowSize;

    /**
     * It's for mode Theather/Pseudo Fullscreen in iOS.
     */
    const modeTheaterFullscreen = isMobile && isIOS && isLandscape;

    /**
     * This mode is only desktop when the screen width is less than 768px
     */
    const modeDesktopResponsive = isDesktop && windowWidth <= screens.md;

    const isTabletOrMobile = isTablet || isMobile;

    const isTabletOnLandscape = isTablet && isLandscape;
    const isMobileOnLandscape = isMobile && isLandscape;

    return {
      anotherBrowser,
      isAndroid,
      isChrome,
      isDesktop,
      isEdge,
      isFirefox,
      isIOS,
      isIpad,
      isIphone,
      isLandscape,
      isMobile,
      isMobileOnLandscape,
      isPortrait,
      isSafari,
      isTablet,
      isTabletOnLandscape,
      isTabletOrMobile,
      modeDesktopResponsive,
      modeTheaterFullscreen,
      windowHeight,
      windowWidth,
    };
  }, [windowSize]);
};
