import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { AppIcon } from "components/elements/AppIcon";

import { HIDE_SCROLL } from "theme/ui";

export const Notes = () => {
  const intl = useIntl();

  const notes = useSelector((state) => state.notes);

  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) return;

    editorRef.current.innerHTML = notes;
  }, [notes]);

  return notes.length === 0 || notes === "" ? (
    <ScrollContainer className="w-full h-full flex flex-col justify-center items-center gap-6 p-12">
      <AppIcon type={AppIcon.types.EMPTY_NOTES} />
      <h3
        className="w-full font-nunito font-normal text-14 text-center text-black"
        style={{ maxWidth: 162 }}
      >
        {intl.formatMessage({ id: "panel.notes.empty.label" })}
      </h3>
    </ScrollContainer>
  ) : (
    <div className="w-full h-full overflow-hidden">
      <NotesContent
        className="h-full w-full overflow-y-scroll text-black text-14 px-12 py-8"
        ref={editorRef}
      />
    </div>
  );
};

const ScrollContainer = styled.div`
  ${HIDE_SCROLL}
`;

const NotesContent = styled.div`
  ${HIDE_SCROLL}
`;
