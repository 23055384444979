import React from "react";
import { useIntl } from "react-intl";
import prettyBytes from "pretty-bytes";

import { AppIcon } from "components/elements/AppIcon";
import { IconClickeable } from "components/elements/IconClickeable";

import { getIconColorByExtension, getIconNameByExtension } from "lib/files";

export const File = ({ file }) => {
  const intl = useIntl();

  const onDownload = () => window.open(file.downloadUrl, "_blank");

  return (
    <div className="flex flex-row items-center p-6 gap-4">
      <div className="flex justify-center items-center p-4">
        <AppIcon
          type={getIconNameByExtension(file.extension)}
          size={24}
          style={{ fill: getIconColorByExtension(file.extension) }}
        />
      </div>
      <div className="w-full flex flex-col gap-2 overflow-hidden">
        <div className="text-14 text-black truncate px-2">{file.name}</div>
        <div className="text-12 text-black50 px-2">{prettyBytes(file.size)}</div>
        <div className="text-12 text-black50 px-2">{file.username}</div>
      </div>
      <div className="flex justify-center items-center p-4">
        <IconClickeable
          className="fill-black25"
          size="sm"
          icon={IconClickeable.types.DOWNLOAD}
          fill="black"
          hoverFill="black"
          label={intl.formatMessage({ id: "panel.files.file.download.label" })}
          onClick={onDownload}
        />
      </div>
    </div>
  );
};
