import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const AppClickeable = ({
  className,
  disabled,
  children,
  onClick,
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        className,
        { "cursor-pointer": !disabled },
        "select-none"
      )}
      onClick={disabled ? () => null : onClick}
      {...restProps}
    >
      {children}
    </div>
  );
};
AppClickeable.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
AppClickeable.defaultProps = {
  className: "",
  disabled: false,
  onClick: () => null,
};
