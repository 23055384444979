export const ICON_SIZES = {
  sm: {
    key: "sm",
    width: 24,
    height: 24,
  },
  md: {
    key: "md",
    width: 36,
    height: 36,
  },
  lg: {
    key: "lg",
    width: 44,
    height: 44,
  },
  xl: {
    key: "xl",
    width: 56,
    height: 56,
  },
};

export const ERROR_VIEWS = {
  IPAD_NOT_SUPPORTED: "IPAD_NOT_SUPPORTED",
  ERROR_404: "ERROR_404",
  REQUEST_TIMESTAMP_EXPIRED: "REQUEST_TIMESTAMP_EXPIRED",
  REQUEST_TIMESTAMP_REQUIRED: "REQUEST_TIMESTAMP_REQUIRED",
  ACCOUNT_ID_REQUIRED: "ACCOUNT_ID_REQUIRED",
  ORGANIZATION_DOESNT_EXIST: "ORGANIZATION_DOESNT_EXIST",
  CHECKSUM_IS_NOT_VALID: "CHECKSUM_IS_NOT_VALID",
  SESSION_DOESNT_EXIST: "SESSION_DOESNT_EXIST",
};

export const ICON_TYPES = {
  ...ERROR_VIEWS,
  CAST: "CAST",
  CHAT_ON: "CHAT_ON",
  CHAT_OFF: "CHAT_OFF",
  CHEVRON_LEFT: "CHEVRON_LEFT",
  CHEVRON_RIGHT: "CHEVRON_RIGHT",
  CHEVRON_UP: "CHEVRON_UP",
  CHEVRON_DOWN: "CHEVRON_DOWN",
  DOC_BOX: "DOC_BOX",
  DOCX_BOX: "DOCX_BOX",
  DOWNLOAD: "DOWNLOAD",
  DOWNLOAD_MULTIPLE: "DOWNLOAD_MULTIPLE",
  EMPTY_LIBRARY: "EMPTY_LIBRARY",
  EMPTY_NOTES: "EMPTY_NOTES",
  EMPTY_SLIDES: "EMPTY_SLIDES",
  EXIT_FULL_SCREEN: "EXIT_FULL_SCREEN",
  EXPAND: "EXPAND",
  FAST_FORWARD: "FAST_FORWARD",
  FAST_REWIND: "FAST_REWIND",
  FULL_SCREEN: "FULL_SCREEN",
  JPEG_BOX: "JPEG_BOX",
  JPG_BOX: "JPG_BOX",
  LIBRARY: "LIBRARY",
  MONITOR: "MONITOR",
  NOTES: "NOTES",
  PAUSE: "PAUSE",
  PDF_BOX: "PDF_BOX",
  PLAY: "PLAY",
  PNG_BOX: "PNG_BOX",
  PRESENTATION: "PRESENTATION",
  POLL: "POLL",
  POLL_OFF: "POLL_OFF",
  PPT_BOX: "PPT_BOX",
  PPTX_BOX: "PPTX_BOX",
  SCREEN_ROTATION: "SCREEN_ROTATION",
  SPEAKER_FACE: "SPEAKER_FACE",
  SUBTITLES: "SUBTITLES",
  SUBTITLES_OFF: "SUBTITLES_OFF",
  VIDEO_LINK: "VIDEO_LINK",
  VIMEO: "VIMEO",
  VOLUME_HIGH: "VOLUME_HIGH",
  VOLUME_LOW: "VOLUME_LOW",
  VOLUME_MUTE: "VOLUME_MUTE",
  YOUTUBE: "YOUTUBE",
};
