import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import classNames from "classnames";

import { PlaybackControls } from "components/PlaybackControls";
import { Presentation } from "components/Presentation";
import { MiniPresentation } from "components/MiniPresentation";
import { Panels } from "components/Panels";
import { MediaPlayer } from "../MediaPlayer";

import { navigationActions } from "store/navigation";
import { slidesActions } from "store/slides";
import { playerActions } from "store/player";

import { usePresentationAreaSize } from "hooks/usePresentationAreaSize";

import { MEDIA_ELEMENT_NAMES } from "lib/player";

import { eventTimer } from "services/timer";

import { idWrapperFullscreen } from "theme/ui";
import { PlaybackContainer, PlaybackGrid } from "./layout";

export const Playback = (captureData) => {
  const dispatch = useDispatch();

  const isMiniPresentationExpanded = useSelector((state) =>
    get(state, "navigation.isMiniPresentationExpanded")
  );
  const meetingId = useSelector((state) => state.navigation.meetingId);
  const fullScreenVisible = useSelector((state) => state.navigation.fullScreenVisible);

  const elemRef = useRef(null);

  const presentationArea = usePresentationAreaSize();

  useEffect(() => {
    /** @type {HTMLAudioElement} */
    const audioEl = document.getElementById(MEDIA_ELEMENT_NAMES.AUDIO);
    if (audioEl) {
      eventTimer.initAudio(audioEl);
    }
  }, [dispatch, meetingId]);

  // Detect fullscreen and update state.
  useEffect(() => {
    const fullScreenVisibleToggled = () => {
      // Closed Components inside Presentation when fullscreen is enabled.
      if (fullScreenVisible) {
        dispatch(slidesActions.slidesVisibleClosed());
        dispatch(navigationActions.miniPresentationDontCollapsed());
        dispatch(playerActions.chatClosed());
      }

      dispatch(navigationActions.fullScreenVisibleToggled());
    };

    document.addEventListener("fullscreenchange", fullScreenVisibleToggled);
    document.addEventListener("webkitfullscreenchange", fullScreenVisibleToggled);

    return () => {
      document.removeEventListener("fullscreenchange", fullScreenVisibleToggled);
      document.removeEventListener("webkitfullscreenchange", fullScreenVisibleToggled);
    };
  }, [dispatch, fullScreenVisible]);

  return (
    <PlaybackContainer
      id={idWrapperFullscreen}
      className="w-full h-full bg-gray"
      fullScreenVisible={fullScreenVisible}
    >
      <PlaybackGrid
        ref={elemRef}
        className={classNames(
          "w-full h-full relative",
          { block: fullScreenVisible },
          { grid: !fullScreenVisible }
        )}
        fullScreenVisible={fullScreenVisible}
        isMiniPresentationExpanded={isMiniPresentationExpanded}
        presentationAreaWidth={presentationArea.width}
        presentationAreaHeight={presentationArea.height}
      >
        {/* MiniPresentation should be always rendered to have acces to the video node from the start. */}
        <MiniPresentation />
        <Presentation />
        <PlaybackControls />
        {!fullScreenVisible && <Panels />}
        <MediaPlayer
          id={MEDIA_ELEMENT_NAMES.AUDIO}
          className="hidden"
          mediaType="audio/mp3"
          debugging
        />
      </PlaybackGrid>
    </PlaybackContainer>
  );
};
