import { PLAYBACK_MEDIA_TYPES } from "lib/player";

/**
 *
 * @param {import("./data").PlaybackData} data
 * @param {Number} time
 */
export const getMediasAtTime = (data, time) => {
  const screenSharingAtTime = data.screenSharings.find((s) => time >= s.start && time < s.end);
  const cameraAtTime = data.cameras.find((c) => time >= c.start && time < c.end);
  const audioAtTime = data.audios.find((a) => time >= a.start && time < a.end);

  return {
    [PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]: screenSharingAtTime || null,
    [PLAYBACK_MEDIA_TYPES.CAMERA]: cameraAtTime || null,
    [PLAYBACK_MEDIA_TYPES.AUDIO]: audioAtTime || null,
  };
};

/**
 *
 * @param {import("./data").PlaybackDataMedia} media
 * @param {Number} time
 */
export const getMediaCurrentTimeAtTime = (dataMedia, time) => {
  if (!dataMedia) return 0;

  if (time >= dataMedia.start && time < dataMedia.end) {
    return time - dataMedia.start;
  }

  return 0;
};
