import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { PlaybackListener } from "./PlaybackListener";

import { eventTimer } from "services/timer";

export const PlaybackDispatcher = ({ meetingId, data }) => {
  const dispatch = useDispatch();
  const [eventTimerInitiated, setEventTimerInitiated] = useState(false);

  useEffect(() => {
    eventTimer.init(dispatch, meetingId, data);
    setEventTimerInitiated(true);

    return () => eventTimer.dispose();
  }, [dispatch, meetingId, data]);

  return eventTimerInitiated ? <PlaybackListener /> : null;
};
