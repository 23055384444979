import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_PLAYER_STATUS, DEFAULT_VOLUME } from "lib/player";

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    status: DEFAULT_PLAYER_STATUS,
    muted: false,
    duration: 0,
    volume: DEFAULT_VOLUME,
    currentTime: 0,
    chatActivated: false,
    playing: false,
  },
  reducers: {
    initialized: (state, action) => {
      state.duration = action.payload.duration;
    },
    playerStatusChanged: (state, action) => {
      state.status = action.payload;
    },
    currentTimeChanged: (state, action) => {
      state.currentTime = action.payload;
    },
    muteToggled: (state) => {
      state.muted = !state.muted;
    },
    volumeChanged: (state, action) => {
      state.volume = action.payload;
    },
    chatActivatedChanged: (state) => {
      state.chatActivated = !state.chatActivated;
    },
    chatClosed: (state) => {
      state.chatActivated = false;
    },
    playingChanged: (state) => {
      state.playing = !state.playing;
    },
    play: (state) => {
      state.playing = true;
    },
    pause: (state) => {
      state.playing = false;
    },
  },
});

export const playerActions = playerSlice.actions;
export const playerReducer = playerSlice.reducer;
