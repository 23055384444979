import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { AppIcon } from "components/elements/AppIcon";

import { ERROR_VIEWS } from "lib/icons";

export const ErrorView = ({ type, code = null }) => {
  const intl = useIntl();

  const { title, description } = useMemo(() => {
    let title = "";
    let description = "";

    switch (type) {
      case ERROR_VIEWS.IPAD_NOT_SUPPORTED: {
        title = intl.formatMessage({ id: "error.ipad.not.supported.title" });
        description = intl.formatMessage({ id: "error.ipad.not.supported.description" });
        break;
      }
      case ERROR_VIEWS.REQUEST_TIMESTAMP_REQUIRED:
      case ERROR_VIEWS.REQUEST_TIMESTAMP_EXPIRED:
      case ERROR_VIEWS.ACCOUNT_ID_REQUIRED:
      case ERROR_VIEWS.ERROR_404:
      case ERROR_VIEWS.ORGANIZATION_DOESNT_EXIST: {
        title = intl.formatMessage({ id: "error.404.title" });
        description = intl.formatMessage({ id: "error.404.description" });
        break;
      }
      case ERROR_VIEWS.SESSION_DOESNT_EXIST: {
        title = intl.formatMessage({ id: "error.session.doesnt.exist.title" });
        description = intl.formatMessage({ id: "error.session.doesnt.exist.description" });
        break;
      }
      default:
        title = intl.formatMessage({ id: "error.404.title" });
        description = intl.formatMessage({ id: "error.404.description" });
        break;
    }

    return { title, description };
  }, [type, intl]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-16 px-4">
      <div className="w-full flex flex-col items-center" style={{ maxWidth: 600 }}>
        {code && (
          <h3 className="text-bluedark text-[1rem] text-center font-normal leading-[2.25rem] font-red-hat px-2">
            Error {code}
          </h3>
        )}
        <h1 className="text-bluedark text-24 text-center font-bold font-red-hat px-8">{title}</h1>
      </div>
      <div className="flex w-full items-center justify-center">
        <AppIcon type={type} />
      </div>
      <h3 className="text-center text-16 px-8" style={{ maxWidth: 600 }}>
        {description}
      </h3>
    </div>
  );
};

ErrorView.types = ERROR_VIEWS;
