import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import styled, { css } from "styled-components";

import { Files } from "./Files";
import { Notes } from "./Notes";
import { Slides } from "./Slides";

import { IconClickeable } from "components/elements/IconClickeable";

import { PANEL_NAMES } from "lib/panels";
import { navigationActions } from "store/navigation";

import { BREAKPOINTS, PANELS_HEADER_LG_HEIGHT, PANELS_HEADER_MD_HEIGHT } from "theme/ui";
import { colors } from "theme";

export const Panels = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const panelActiveName = useSelector((state) => state.navigation.panelActiveName);

  const renderPanels = useCallback(() => {
    switch (panelActiveName) {
      case PANEL_NAMES.NOTES:
        return <Notes />;
      case PANEL_NAMES.SLIDES:
        return <Slides />;
      default:
        return <Files />;
    }
  }, [panelActiveName]);

  return (
    <div
      className="w-full h-full overflow-hidden flex md:flex-col-reverse flex-col shadow-all bg-white rounded-none md:rounded-xxl"
      style={{ gridArea: "panels" }}
    >
      <div className="w-full h-full relative overflow-hidden">{renderPanels()}</div>
      <PanelsHeader className="w-full relative grid grid-cols-3 justify-items-center items-center bg-white">
        <IconClickeable
          size="sm"
          icon={IconClickeable.types.NOTES}
          fill="black50"
          hoverFill="black"
          activeFill="black"
          activeHoverFill="black"
          label={intl.formatMessage({ id: "panel.notes.icon.label" })}
          tooltipPosition={IconClickeable.tooltipPosition.BOTTOM}
          active={panelActiveName === PANEL_NAMES.NOTES}
          onClick={() => dispatch(navigationActions.panelActiveNameChanged(PANEL_NAMES.NOTES))}
        />
        <IconClickeable
          size="sm"
          icon={IconClickeable.types.PRESENTATION}
          fill="black50"
          hoverFill="black"
          activeFill="black"
          activeHoverFill="black"
          label={intl.formatMessage({ id: "panel.slides.icon.label" })}
          tooltipPosition={IconClickeable.tooltipPosition.BOTTOM}
          active={panelActiveName === PANEL_NAMES.SLIDES}
          onClick={() => dispatch(navigationActions.panelActiveNameChanged(PANEL_NAMES.SLIDES))}
        />
        <IconClickeable
          size="sm"
          icon={IconClickeable.types.LIBRARY}
          fill="black50"
          hoverFill="black"
          activeFill="black"
          activeHoverFill="black"
          label={intl.formatMessage({ id: "panel.files.icon.label" })}
          tooltipPosition={IconClickeable.tooltipPosition.BOTTOM}
          active={panelActiveName === PANEL_NAMES.FILES}
          onClick={() => dispatch(navigationActions.panelActiveNameChanged(PANEL_NAMES.FILES))}
        />

        <ActiveBottomBar
          className="absolute left-0 flex justify-center items-center select-none"
          panelActiveName={panelActiveName}
        >
          <div className="bar bg-turquoise rounded-sm" />
        </ActiveBottomBar>
      </PanelsHeader>
    </div>
  );
};

const PanelsHeader = styled.div`
  height: ${PANELS_HEADER_LG_HEIGHT}px;
  box-shadow: 2px 2px 4px 0px rgba(35, 41, 61, 0.12);

  @media (max-width: ${BREAKPOINTS.md}px) {
    height: ${PANELS_HEADER_MD_HEIGHT}px;
    box-shadow: 4px -4px 8px 0px rgba(35, 41, 61, 0.12);
  }
`;

const moveBottomBar = (panelActiveName) => {
  switch (panelActiveName) {
    case PANEL_NAMES.NOTES:
      return css`
        transform: translateX(0px);
      `;
    case PANEL_NAMES.SLIDES:
      return css`
        transform: translateX(100%);
      `;
    default:
      return css`
        transform: translateX(200%);
      `;
  }
};

const ActiveBottomBar = styled.div`
  width: calc(100% / ${Object.keys(PANEL_NAMES).length});
  bottom: 4px;

  .bar {
    width: 48px;
    height: 4px;
  }

  ${(props) => moveBottomBar(props.panelActiveName)};

  transition: transform 0.25s;
`;
