import { useEffect, useState } from "react";
import { eventTimer } from "services/timer";

export const useWhiteboardPointer = () => {
  const [pointerCoordinates, setPointerCoordinates] = useState(null);

  useEffect(() => {
    const subscription = eventTimer.pointer$.subscribe((coordinates) => {
      if (coordinates.x >= 0 && coordinates.y >= 0) {
        setPointerCoordinates({
          top: `${coordinates.y * 100}%`,
          left: `${coordinates.x * 100}%`,
        });
      } else {
        setPointerCoordinates(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { pointerCoordinates };
};
