import { createSlice } from "@reduxjs/toolkit";

export const drawingsSlice = createSlice({
  name: "drawings",
  initialState: {
    drawings: [],
    freePen: [],
  },
  reducers: {
    drawingsUpdated: (state, action) => {
      state.drawings = action.payload;
    },
    freePenUpdated: (state, action) => {
      state.freePen = action.payload;
    },
  },
});

export const drawingsActions = drawingsSlice.actions;
export const drawingsReducer = drawingsSlice.reducer;
