import { useEffect, useState } from "react";

import { eventTimer } from "services/timer";

export const useWhiteboardSlides = ({ slides }) => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [currentSlide, setCurrentSlide] = useState(null);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const subscription = eventTimer.slides$.subscribe((slideInteraction) => {
      if (slideInteraction !== null) {
        const { fileId, slideIndex, zoom, x, y } = slideInteraction;
        const slide = slides.find((slide) => slide.fileId === fileId && slide.index === slideIndex);

        setZoom(zoom);
        setCoordinates({ x, y });

        if (slide) {
          const { webp, source, width, height } = slide;
          setCurrentSlide({ webp, source, width, height });
        } else setCurrentSlide(null);
      } else {
        setCurrentSlide(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [slides]);
  return { coordinates, currentSlide, zoom };
};
