import { idWrapperFullscreen } from "theme/ui";

export const handleToggleFulScreen = (fullScreenVisible) => {
  const element = document.getElementById(idWrapperFullscreen); // This id is in Content/PresentationWrapper.

  if (!fullScreenVisible) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else {
      throw new Error("there is no supported full screen api available");
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    }
  }
};
