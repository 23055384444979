export const DEVICES_TYPE = {
  DESKTOP: undefined, // On desktop the type value always is undefined.
  TABLET: "TABLET",
  MOBILE: "MOBILE",
};

export const DEVICE_OS_NAMES = {
  IOS: "IOS",
  ANDROID: "ANDROID",
};

export const DEVICE_MODELS = {
  IPAD: "IPAD",
  IPHONE: "IPHONE",
};

export const BROWSER_TYPES = {
  CHROME: "CHROME",
  EDGE: "EDGE",
  SAFARI: "SAFARI",
  FIREFOX: "FIREFOX",
};

export const DEVICE_ORIENTATION = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};
