import { BehaviorSubject, Subject, interval, fromEvent, combineLatest, merge } from "rxjs";
import { map, tap, startWith, shareReplay, distinctUntilChanged } from "rxjs/operators";

import isEqual from "lodash.isequal";

import { DEFAULT_PLAYER_STATUS, MEDIA_EVENT_STATUS, PLAYBACK_MEDIA_TYPES } from "lib/player";

import { playerActions } from "store/player";
import { filesActions } from "store/files";
import { navigationActions } from "store/navigation";
import { slidesActions } from "store/slides";
import { notesActions } from "store/notes";
import { usersActions } from "store/users";
import { messagesActions } from "store/mesages";
import { videosActions } from "store/videos";

import { getPointerAtTime } from "./pointers";
import { getDrawingsAtTime } from "./drawings";
import { getMessagesAtTime } from "./messages";
import { getPresenterAtTime } from "./users";
import { getVideoSharingAtTime, getVideos } from "./videoSharings";
import { getMediaCurrentTimeAtTime, getMediasAtTime } from "./medias";
import { getSlideInteractionForTime, getSlideTumbnails } from "./slides";

const INTERVAL = 1000;

const canPlayEventType = "canplay";
const waitingEventType = "waiting";

const normalizeTime = (time) => parseFloat(time.toFixed(1));

export const eventTimer = new (class {
  constructor() {
    this.intervalObservable$ = interval(INTERVAL);
    this.currentTime = 0;
    this.computedCurrentTime = 0;
    this.status = DEFAULT_PLAYER_STATUS;
    this.meetingId = null;
    this.dispatch = null;

    /**
     * @type {import("./data").PlaybackData}
     */
    this.data = {
      meeting: {
        duration: 0,
      },
      slides: {},
      screenSharings: [],
      cameras: [],
      audios: [],
      pointers: [],
      drawings: [],
      messages: [],
      files: [],
      videoSharings: [],
      notes: "",
      users: {
        users: [],
        presenters: [],
      },
    };

    this.timeUpdated$ = new Subject();

    this.slides$ = this.timeUpdated$.pipe(
      map((time) => getSlideInteractionForTime(this.data, time)),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    this.medias$ = this.timeUpdated$.pipe(
      startWith(0),
      map((time) => ({ medias: getMediasAtTime(this.data, time), time })),
      distinctUntilChanged((m1, m2) => isEqual(m1.medias, m2.medias)),
      shareReplay(1)
    );

    this.pointer$ = this.timeUpdated$.pipe(
      map((time) => getPointerAtTime(this.data, time)),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    this.drawings$ = this.timeUpdated$.pipe(
      map((time) => getDrawingsAtTime(this.data, time)),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    this.messages$ = this.timeUpdated$.pipe(
      map((time) => getMessagesAtTime(this.data, time)),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    this.videoSharings$ = this.timeUpdated$.pipe(
      map((time) => getVideoSharingAtTime(this.data, time)),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    this.presenter$ = this.timeUpdated$.pipe(
      map((time) => getPresenterAtTime(this.data, time)),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );

    this.playIsRequested$ = new BehaviorSubject(false);

    this.playbackMedias = {
      /**
       * @type {HTMLVideoElement | null}
       */
      [PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]: null,
      /**
       * @type {HTMLVideoElement | null}
       */
      [PLAYBACK_MEDIA_TYPES.CAMERA]: null,
      /**
       * @type {HTMLAudioElement | null}
       */
      [PLAYBACK_MEDIA_TYPES.AUDIO]: null,
      // audio: null,
    };

    this.playbackMediasStatuses$ = {
      /**
       * @type {import("rxjs").Observable | null}
       */
      [PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]: null,
      /**
       * @type {import("rxjs").Observable | null}
       */
      [PLAYBACK_MEDIA_TYPES.CAMERA]: null,
      /**
       * @type {import("rxjs").Observable | null}
       */
      [PLAYBACK_MEDIA_TYPES.AUDIO]: null,
    };

    /**
     * @type {import("rxjs").Subscription | null}
     */
    this.playOrStopMediasSubscription = null;

    /**
     * @type {import("rxjs").Subscription | null}
     */
    this.updatePlayOrStopMediasSubscription = null;

    /**
     * @type {import("rxjs").Subscription | null}
     */
    this.intervalSubscription = null;
  }

  #initIntervalSubscription = () => {
    if (!this.intervalSubscription) {
      this.intervalSubscription = this.intervalObservable$.subscribe(() => {
        // Stop everything when time is up
        if (this.computedCurrentTime >= this.data.meeting.duration) {
          this.computedCurrentTime = 0;
          this.timeUpdated$.next(0);

          this.#stopTimer();
          this.#stopMedias();
          this.playIsRequested$.next(false);

          this.dispatch(playerActions.pause());
        } else {
          this.computedCurrentTime = this.computedCurrentTime + INTERVAL / 1000;
          this.timeUpdated$.next(this.computedCurrentTime);
        }
      });
    }
  };

  #startPlaying = () => {
    if (this.#checkNotInitialized()) return;
    if (this.#checkMediasNotInitialized()) return;

    this.#initIntervalSubscription();

    if (this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING].src) {
      this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]
        .play()
        .catch((error) => console.error("There was an error trying to play screen sharing", error));
    }
    if (this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA].src) {
      this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA]
        .play()
        .catch((error) => console.error("There was an error trying to play camera", error));
    }
    if (this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO].src) {
      this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO]
        .play()
        .catch((error) => console.error("There was an error trying to play audio", error));
    }
  };

  #checkNotInitialized = () => {
    if (!this.dispatch) {
      console.warn("Event Timer instance was not initialized");
      return true;
    }
    return false;
  };

  #checkMediasNotInitialized = () => {
    if (!this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]) {
      console.warn("Event timer was called without screen sharing media initialized");
      return true;
    }
    if (!this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA]) {
      console.warn("Event timer was called without camera media initialized");
      return true;
    }
    if (!this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO]) {
      console.warn("Event timer was called without audio initialized");
      return true;
    }
    return false;
  };

  init(dispatch, meetingId, data) {
    if (!dispatch) {
      console.error("dispatch is needed for initialization");
      return;
    }
    if (!meetingId) {
      console.error("meetingId is needed for initialization");
      return;
    }

    this.dispatch = dispatch;
    this.meetingId = meetingId;

    this.data = {
      meeting: { duration: data.medias.audios[0].end },
      cameras: data.medias.cameras,
      audios: data.medias.audios,
      screenSharings: data.medias.screen_sharings,
      files: data.files,
      messages: data.messages,
      notes: data.notes,
      pointers: [],
      videoSharings: data.videos,
      drawings: data.drawings,
      users: data.users,
      slides: data.slides,
    };

    const thumbnails = getSlideTumbnails(this.data);
    const videos = getVideos(this.data.videoSharings);

    this.dispatch(playerActions.initialized({ duration: this.data.meeting.duration }));
    this.dispatch(navigationActions.initialized(meetingId));
    this.dispatch(slidesActions.initialized({ thumbnails, slides: data.slides.slides }));
    this.dispatch(filesActions.received(this.data.files));
    this.dispatch(notesActions.received(this.data.notes));
    this.dispatch(usersActions.presenterUpdated(this.data.users.presenters[0] || null));
    this.dispatch(videosActions.received(videos));
  }

  requestPlay() {
    if (this.#checkNotInitialized()) return;

    this.playIsRequested$.next(true);
  }

  requestSeek(time) {
    if (this.#checkNotInitialized()) return;
    if (this.#checkMediasNotInitialized()) return;

    const normalizedTime = normalizeTime(time);

    this.currentTime = normalizedTime;
    this.dispatch(playerActions.currentTimeChanged(normalizedTime));
    this.computedCurrentTime = normalizedTime;

    this.dispatch(messagesActions.messagesUpdated([]));

    const medias = getMediasAtTime(this.data, time);
    if (medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]) {
      this.#updateCurrentTimeMedia(
        this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING],
        getMediaCurrentTimeAtTime(medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING], time)
      );
    }
    if (medias[PLAYBACK_MEDIA_TYPES.CAMERA]) {
      this.#updateCurrentTimeMedia(
        this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA],
        getMediaCurrentTimeAtTime(medias[PLAYBACK_MEDIA_TYPES.CAMERA], time)
      );
    }
    if (medias[PLAYBACK_MEDIA_TYPES.AUDIO]) {
      this.#updateCurrentTimeMedia(
        this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO],
        getMediaCurrentTimeAtTime(medias[PLAYBACK_MEDIA_TYPES.AUDIO], time)
      );
    }

    // If is Pause, requestPlay is executed automatically when user dragged or clicked the timeline.
    if (!this.playIsRequested$.value) {
      this.requestPlay();
      this.dispatch(playerActions.play());
    }
  }

  #stopTimer = () => {
    if (this.#checkNotInitialized()) return;

    const normalizedTime = normalizeTime(this.computedCurrentTime);
    this.currentTime = normalizedTime;
    this.dispatch(playerActions.currentTimeChanged(normalizedTime));

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  };

  #stopMedias = () => {
    if (this.#checkNotInitialized()) return;
    if (this.#checkMediasNotInitialized()) return;

    if (this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING].src) {
      this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING].pause();
    }
    if (this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA].src) {
      this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA].pause();
    }
    if (this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO].src) {
      this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO].pause();
    }
  };

  requestPause() {
    if (this.#checkNotInitialized()) return;

    this.#stopTimer();

    this.playIsRequested$.next(false);
    this.#stopMedias();
  }

  /**
   *
   * @param {HTMLVideoElement | HTMLAudioElement} element
   * @param {string} key
   */
  #initMedia = (element, key) => {
    this.playbackMedias[key] = element;

    const mediaWaiting$ = fromEvent(element, waitingEventType).pipe(
      startWith(MEDIA_EVENT_STATUS.WAITING),
      map(() => MEDIA_EVENT_STATUS.WAITING)
    );

    const mediaCanPlay$ = fromEvent(element, canPlayEventType).pipe(
      map(() => MEDIA_EVENT_STATUS.CAN_PLAY)
    );

    this.playbackMediasStatuses$[key] = merge(mediaWaiting$, mediaCanPlay$).pipe(shareReplay(1));

    if (
      this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING] &&
      this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.CAMERA] &&
      this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.AUDIO]
    ) {
      this.updatePlayOrStopMediasSubscription = this.medias$
        .pipe(
          tap(({ medias, time }) => {
            this.#updatePlayOrStopMediasSubscription(medias, time);
          })
        )
        .subscribe();
    }
  };

  /**
   *
   * @param {HTMLVideoElement|HTMLAudioElement} media
   * @param {Number} time
   */
  #updateCurrentTimeMedia = (media, time) => {
    media.currentTime = time;
  };

  /**
   *
   * @param {HTMLVideoElement|HTMLAudioElement} media
   * @param {String} source
   * @param {Number} time
   */
  #loadMedia = (media, source, time) => {
    if (media.src !== source) {
      media.src = source;
      media.currentTime = time;
      media.load();
    }
  };

  /**
   *
   * @param {HTMLVideoElement|HTMLAudioElement} media
   */
  #unloadMedia = (media) => {
    media.pause();
    media.removeAttribute("src");
    media.load();
  };

  #updatePlayOrStopMediasSubscription = (medias, time) => {
    if (this.#checkNotInitialized()) return;
    if (this.#checkMediasNotInitialized()) return;

    const mediaStatusesObservers = [];

    if (medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]) {
      this.#loadMedia(
        this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING],
        medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING].source,
        getMediaCurrentTimeAtTime(medias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING], time)
      );
      mediaStatusesObservers.push(
        this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]
      );
    } else {
      this.#unloadMedia(this.playbackMedias[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING]);
    }
    if (medias[PLAYBACK_MEDIA_TYPES.CAMERA]) {
      this.#loadMedia(
        this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA],
        medias[PLAYBACK_MEDIA_TYPES.CAMERA].source,
        getMediaCurrentTimeAtTime(medias[PLAYBACK_MEDIA_TYPES.CAMERA], time)
      );
      mediaStatusesObservers.push(this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.CAMERA]);
    } else {
      this.#unloadMedia(this.playbackMedias[PLAYBACK_MEDIA_TYPES.CAMERA]);
    }
    if (medias[PLAYBACK_MEDIA_TYPES.AUDIO]) {
      this.#loadMedia(
        this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO],
        medias[PLAYBACK_MEDIA_TYPES.AUDIO].source,
        getMediaCurrentTimeAtTime(medias[PLAYBACK_MEDIA_TYPES.AUDIO], time)
      );
      mediaStatusesObservers.push(this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.AUDIO]);
    } else {
      this.#unloadMedia(this.playbackMedias[PLAYBACK_MEDIA_TYPES.AUDIO]);
    }

    if (this.playOrStopMediasSubscription) {
      this.playOrStopMediasSubscription.unsubscribe();
    }

    if (!mediaStatusesObservers.length) return;

    if (
      this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.SCREEN_SHARING] &&
      this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.CAMERA] &&
      this.playbackMediasStatuses$[PLAYBACK_MEDIA_TYPES.AUDIO]
    ) {
      this.playOrStopMediasSubscription = combineLatest([
        combineLatest(mediaStatusesObservers),
        this.playIsRequested$,
      ]).subscribe(([playbackMediaStatuses, playIsRequested, _medias]) => {
        const thereIsMediaWaiting =
          playbackMediaStatuses.findIndex((status) => status === MEDIA_EVENT_STATUS.WAITING) > -1;

        if (thereIsMediaWaiting) {
          this.#stopMedias();
          this.#stopTimer();
        } else if (playIsRequested) {
          this.#startPlaying();
        }
      });
    }
  };

  /**
   *
   * @param {HTMLVideoElement} video
   */
  initScreenSharingVideo(video) {
    this.#initMedia(video, PLAYBACK_MEDIA_TYPES.SCREEN_SHARING);
  }

  /**
   *
   * @param {HTMLVideoElement} video
   */
  initCameraVideo(video) {
    this.#initMedia(video, PLAYBACK_MEDIA_TYPES.CAMERA);
  }

  /**
   *
   * @param {HTMLAudioElement} audio
   */
  initAudio(audio) {
    this.#initMedia(audio, PLAYBACK_MEDIA_TYPES.AUDIO);
  }

  dispose() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }

    if (this.playOrStopMediasSubscription) {
      this.playOrStopMediasSubscription.unsubscribe();
      this.playOrStopMediasSubscription = null;
    }

    if (this.updatePlayOrStopMediasSubscription) {
      this.updatePlayOrStopMediasSubscription.unsubscribe();
      this.updatePlayOrStopMediasSubscription = null;
    }

    this.status = DEFAULT_PLAYER_STATUS;
    this.currentTime = 0;
    this.computedCurrentTime = 0;
  }
})();

window.eventTimer = eventTimer;
