import React from "react";

export const DrawingWrapper = ({
  children,
  dataDrawing,
  drawingOffset,
  heightDrawing,
  widthDrawing,
  zoomFactor,
}) => {
  const { id: drawingId } = dataDrawing;

  return (
    <foreignObject
      id={`drawing${drawingId}`}
      x={(dataDrawing.x + drawingOffset.x) * zoomFactor}
      y={(dataDrawing.y + drawingOffset.y) * zoomFactor}
      width={widthDrawing}
      height={heightDrawing}
      className="overflow-visible"
      style={{
        pointerEvents: "auto",
        transformOrigin: "center center",
        transformBox: "fill-box",
      }}
    >
      {children}
    </foreignObject>
  );
};
